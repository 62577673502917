import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { JOBS_API, JOBS_STATUS_UPDATE_API } from '../apiEndpoints';

export const updateStatus = createAsyncThunk(
  'jobs/updateStatus',
  async ({ jobId }, { rejectWithValue, dispatch }) => {
    try {
      await axios({
        method: 'post',
        url: `${JOBS_STATUS_UPDATE_API}/${jobId}`,
        data: { status: 'inactive' },
        headers: { 'Content-Type': 'application/json' },
      });
      return jobId;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log('err.response: ', err.response);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchJobsData = createAsyncThunk(
  'jobs/fetchData',
  async (queryParams, { getState }) => {
    const { offset = 0, replaceExisting = true, ...restParams } = queryParams;
    // const limit = getState().jobs.limit;
    const response = await axios.get(JOBS_API, {
      params: { ...queryParams, offset },
    });

    const response1 = {
      data: {
        company_name: 'True Hire',
        data: [
          {
            applicants: 0,
            id: 110,
            job_title: '',
            job_url: 'http://localhost:5003/jobs/__NjTRqxbp',
            posted_by: 'demo@truehire.work',
            posted_on: 1721147289,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 109,
            job_title: '',
            job_url: 'http://localhost:5003/jobs/__2PU2a5Ps',
            posted_by: 'demo@truehire.work',
            posted_on: 1721145933,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 108,
            job_title: '',
            job_url: 'http://localhost:5003/jobs/__vvyf9V0O',
            posted_by: 'demo@truehire.work',
            posted_on: 1720437465,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 104,
            job_title: '28',
            job_url: 'http://localhost:5003/jobs/_28_joDZynx2',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512834,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 103,
            job_title: '27',
            job_url: 'http://localhost:5003/jobs/_27_9sKo50TV',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512830,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 102,
            job_title: '26',
            job_url: 'http://localhost:5003/jobs/_26_kq0fIUSe',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512826,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 101,
            job_title: '25',
            job_url: 'http://localhost:5003/jobs/_25_3FKl30ID',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512820,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 99,
            job_title: '23',
            job_url: 'http://localhost:5003/jobs/_23_QmeQMFk3',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512810,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 98,
            job_title: '22',
            job_url: 'http://localhost:5003/jobs/_22_AluZYy8q',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512806,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 97,
            job_title: '21',
            job_url: 'http://localhost:5003/jobs/_21_qoD8fszn',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512803,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 96,
            job_title: '20',
            job_url: 'http://localhost:5003/jobs/_20_aDMSAg4V',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512799,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 95,
            job_title: '19',
            job_url: 'http://localhost:5003/jobs/_19_VZvFHpev',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512794,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 94,
            job_title: '18',
            job_url: 'http://localhost:5003/jobs/_18_LdcunOio',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512791,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 93,
            job_title: '17',
            job_url: 'http://localhost:5003/jobs/_17_DYnX3att',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512787,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 92,
            job_title: '16',
            job_url: 'http://localhost:5003/jobs/_16_QhkCCv54',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512783,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 91,
            job_title: '15',
            job_url: 'http://localhost:5003/jobs/_15_YvF61DiK',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512779,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 90,
            job_title: '14',
            job_url: 'http://localhost:5003/jobs/_14_oas1fZx9',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512775,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 89,
            job_title: '13',
            job_url: 'http://localhost:5003/jobs/_13_DTQJDeV9',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512771,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 88,
            job_title: '12',
            job_url: 'http://localhost:5003/jobs/_12_d2kRuxzt',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512767,
            status: true,
            team: '',
          },
          {
            applicants: 0,
            id: 87,
            job_title: '11',
            job_url: 'http://localhost:5003/jobs/_11_Yv0nhnJo',
            posted_by: 'amrit@truehire.work',
            posted_on: 1719512764,
            status: true,
            team: '',
          },
        ],
        teams: [
          'Backup and Disaster Recovery - Google Cloud',
          'Marketing',
          'Human Resources (HR)',
          'Finance',
        ],
        trial_left: 33,
      },
    };

    return {
      data: response.data.data,
      hasMore: response.data.data.length === 20,
      offset: offset + response.data.data.length,
      teams: response.data.teams,
      replaceExisting,
    };
  }
);

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    offset: 0,
    hasMore: false,
  },
  reducers: {
    saveDataJobs: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobsData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobsData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.replaceExisting) {
          state.data = action.payload.data;
        } else {
          state.data = [...state.data, ...action.payload.data];
        }
        state.hasMore = action.payload.hasMore;
        state.offset = action.payload.offset;
        state.teams = action.payload.teams;
      })
      .addCase(fetchJobsData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        const currentData = current(state.data);
        state.updateStatusSuccess = true;
        state.updateStatusError = null;
        state.data = currentData.filter((obj) => obj.id !== action.payload);
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.updateStatusSuccess = false;
        state.updateStatusError = action.error.message;
      });
  },
});
export const { saveDataJobs } = jobsSlice.actions;
export default jobsSlice.reducer;
// const sampleResponse = {
//   data: [
//     {
//       job_title: 'title',
//       team: 'team',
//       applicants: 'applicants',
//       posted_by: 'posted_by',
//       posted_on: 'posted_on',
//       status: 'status',
//     },
//     {
//       job_title: 'title1',
//       team: 'team1',
//       applicants: 'applicants1',
//       posted_by: 'posted_by1',
//       posted_on: 'posted_on1',
//       status: 'status1',
//     },
//   ],
//   has_more: false,
//   teams: [],
//   offset: 0,
// };
// return sampleResponse;
