import { createSlice } from '@reduxjs/toolkit';

const submitModalSlice = createSlice({
  name: 'submitModal',
  initialState: { isOpen: false, data: null },
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
      state.data = action.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.data = null;
    },
  },
});

export const { openModal, closeModal } = submitModalSlice.actions;

export default submitModalSlice.reducer;
