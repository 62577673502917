import styles from './Dropdown.module.css';

import { get } from 'lodash';

const {
  teamDropdown,
  teamDropdownItem,
  highlightedDropdownItem,
  selectedBackground,
} = styles;

const DropdownComponent = ({
  currentDropdownState,
  dropdownRef,
  items,
  selectedIndices,
  setValue,
  setCurrentDropdownState,
  dropdownStateName,
  dropdownId,
  indexName,
  setSelectedIndices,
  getValues,
}) => {
  return (
    currentDropdownState === dropdownStateName && (
      <div className={teamDropdown} ref={dropdownRef}>
        <div
          tabIndex={0}
          id={dropdownId}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              setCurrentDropdownState('');
              // setValue(dropdownStateName, '');
              setValue(
                dropdownStateName,
                get(items, `[${selectedIndices[indexName]}].name`, '')
              );
            } else if (event.key === 'ArrowDown') {
              event.preventDefault();
              setSelectedIndices((prevState) => {
                const prevIndex = prevState[indexName];
                return {
                  ...prevState,
                  [indexName]: Math.min(prevIndex + 1, items.length - 1),
                };
              });
            } else if (event.key === 'ArrowUp') {
              event.preventDefault();
              setSelectedIndices((prevState) => {
                const prevIndex = prevState[indexName];
                return {
                  ...prevState,
                  [indexName]: Math.max(prevIndex - 1, 0),
                };
              });
            }
          }}>
          {items?.map((item, index) => {
            return (
              <span
                key={item.name}
                className={`${teamDropdownItem} ${
                  selectedIndices[indexName] === index
                    ? highlightedDropdownItem
                    : ''
                } ${
                  item.name === getValues(dropdownStateName)
                    ? selectedBackground
                    : ''
                }`}
                onClick={() => {
                  setValue(dropdownStateName, item.name);
                  setCurrentDropdownState('');
                }}>
                {item.name}
              </span>
            );
          })}
        </div>
      </div>
    )
  );
};

export default DropdownComponent;
