import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { openModal, closeModal } from './submitModalSlice';
import {
  LOGIN_API,
  FORGOT_PASSWORD_API,
  RESET_PASSWORD_API,
} from '../apiEndpoints';

export const postLogin = createAsyncThunk(
  'login/postData',
  async (loginData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: 'post',
        url: LOGIN_API,
        data: loginData,
        headers: { 'Content-Type': 'application/json' },
      });
      //   dispatch(openModal(response.data));
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log('err.response: ', err.response);
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetEmail = createAsyncThunk(
  'login/resetEmail',
  async (resetEmailData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: 'post',
        url: FORGOT_PASSWORD_API,
        data: resetEmailData,
        headers: { 'Content-Type': 'application/json' },
      });
      //   dispatch(openModal(response.data));
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log('err.response: ', err.response);
      return rejectWithValue(err.response.data);
    }
  }
);

export const tokenValidation = createAsyncThunk(
  'login/tokenValidation',
  async (tokenValidationData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${RESET_PASSWORD_API}/${tokenValidationData}`,
        headers: { 'Content-Type': 'application/json' },
      });
      //   dispatch(openModal(response.data));
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log('err.response: ', err.response);
      return rejectWithValue(err.response.data);
    }
  }
);

export const passwordReset = createAsyncThunk(
  'login/passwordReset',
  async ({ password, token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: 'post',
        url: `${RESET_PASSWORD_API}/${token}`,
        data: { new_password: password },
        headers: { 'Content-Type': 'application/json' },
      });
      //   dispatch(openModal(response.data));
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log('err.response: ', err.response);
      return rejectWithValue(err.response.data);
    }
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState: { data: {}, status: 'idle', error: null },
  reducers: {
    saveDataCreateJob: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(resetEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetEmail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(resetEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(tokenValidation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(tokenValidation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(tokenValidation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(passwordReset.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(passwordReset.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(passwordReset.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const { saveDataCreateJob } = loginSlice.actions;
export default loginSlice.reducer;
