import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { produce } from 'immer';
import { saveDataDashboard } from '../../redux/dashboardSlice';
import ReactJson from '@uiw/react-json-view';
import './modal.css';

import { closeModal } from '../../redux/modalSlice'; // import your closeModal and saveData actions

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

function convertValueToString(value) {
  if (value === null) {
    return 'null';
  }
  if (value === undefined) {
    return 'undefined';
  }
  if (Array.isArray(value) && value.length === 0) {
    return '[]';
  }
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return '{}';
  }
  return value;
}

function convertStringToOriginal(value) {
  if (value === 'null') {
    return null;
  }
  if (value === 'undefined') {
    return undefined;
  }
  if (value === '[]') {
    return [];
  }
  if (value === '{}') {
    return {};
  }
  return value;
}

function convertObjectValues(obj) {
  let newObj = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      newObj[key] = convertObjectValues(obj[key]);
    } else {
      newObj[key] = convertStringToOriginal(obj[key]);
    }
  }
  return newObj;
}
function EditModal() {
  const dispatch = useDispatch();
  // const storeData = useSelector((state) => state);
  const { isOpen } = useSelector((state) => state.modal);
  const [formData, setFormData] = useState({});
  const [displayOptions, setDisplayOptions] = useState({
    displayDataTypes: false,
    displayObjectSize: false,
  });
  const handleCheckboxChange = (event) => {
    setDisplayOptions({
      ...displayOptions,
      [event.target.name]: event.target.checked,
    });
  };
  // useEffect(() => {
  //   setFormData(storeData);
  // }, [storeData]);

  const handleSave = ({ dashboard }) => {
    // const dataDashboard = convertObjectValues(formData.dashboard);
    dispatch(saveDataDashboard(dashboard));
    // setFormData({});
    // dispatch(closeModal());
  };

  const handleChange = (event) => {
    const name = event.target.name.split('.');
    setFormData(
      produce((draft) => {
        let temp = draft;
        for (let i = 0; i < name.length - 1; i++) {
          temp = temp[name[i]];
        }
        temp[name[name.length - 1]] = event.target.value;
      })
    );
  };
  function renderFields(data, prefix = '') {
    const keys = Object.keys(data);
    return keys.map((key, index) => {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (
        typeof data[key] === 'object' &&
        data[key] !== null &&
        Object.keys(data[key]).length > 0
      ) {
        return renderFields(data[key], newKey);
      }
      return (
        <>
          <div key={newKey} style={{ marginBottom: '2rem', fontSize: '2rem' }}>
            <label style={{ display: 'inline-block', marginBottom: '5px' }}>
              {newKey}
            </label>
            <input
              name={newKey}
              value={convertValueToString(data[key])}
              onChange={handleChange}
              disabled={['{}', '[]'].includes(convertValueToString(data[key]))}
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: ['{}', '[]'].includes(
                  convertValueToString(data[key])
                )
                  ? '#e9ecef'
                  : 'white',
              }}
            />
          </div>
        </>
      );
    });
  }
  const handleEdit = (edit) => {
    handleSave(edit.updated_src);
    // setTimeout(() => {
    //   const textarea = document.querySelector(
    //     '.variable-value .variable-editor'
    //   );

    //   if (textarea) {
    //     textarea.focus();
    //   }
    // }, 0);
  };

  const handleDelete = (value) => {
    handleSave(value.updated_src);
  };

  const handleAdd = (value) => {
    handleSave(value.updated_src);
  };
  return (
    <Modal
      isOpen={isOpen}
      // onRequestClose={() => {
      //   dispatch(closeModal());
      // }}
    >
      {/* {renderFields(formData)} */}
      <div
        style={{ marginLeft: '20px', marginBottom: '20px', display: 'flex' }}>
        <p>Keyboard Shortcuts</p>
        <ul>
          <li>- To close modal, ctrl + q</li>
          <li>- When editing a value, try ctrl + enter to submit changes</li>
          <li>- When editing a value, try Escape key to cancel</li>
          <li>- When adding a new key, try Enter to submit</li>
          <li>- When adding a new key, try Escape to cancel</li>
        </ul>

        <div className='modal-json-options'>
          {Object.keys(displayOptions).map((key) => (
            <label
              key={key}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                padding: '10px',
                backgroundColor: displayOptions[key] ? '#ADD8E6' : 'white',
                borderRadius: '5px',
                transition: 'background-color 0.3s ease',
              }}>
              <input
                type='checkbox'
                name={key}
                checked={displayOptions[key]}
                onChange={handleCheckboxChange}
                style={{ width: '20px', height: '20px' }}
              />
              <span style={{ fontSize: '16px' }}>{key}</span>
            </label>
          ))}
        </div>
      </div>
      <ReactJson
        value={formData}
        // onEdit={handleEdit}
        // onDelete={handleDelete}
        // onAdd={handleAdd}
        // displayArrayKey={false}
        {...displayOptions}
      />
      {/* <button
        onClick={handleSave}
        style={{
          width: '20rem',
          padding: '2rem 0px',
          backgroundColor: 'green',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          fontSize: '2rem',
          textAlign: 'center',
        }}>
        Save
      </button> */}
    </Modal>
  );
}

export default EditModal;
