import React from 'react';

const TermsAndConditions = () => {
  const styles = {
    container: {
      fontFamily: 'Mulish, sans-serif',
      lineHeight: '1.6',
      padding: '20px',
      maxWidth: '800px',
      margin: 'auto',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    heading: {
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    subheading: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginTop: '20px',
    },
    paragraph: {
      marginBottom: '10px',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Terms and Conditions</h1>
      <p style={styles.paragraph}>Last Updated: [Date]</p>
      <p style={styles.paragraph}>
        Welcome to [WebApp Name]. By signing up as a recruiter on our platform,
        you agree to abide by the following terms and conditions. Please read
        them carefully.
      </p>
      <h2 style={styles.subheading}>1. Acceptance of Terms</h2>
      <p style={styles.paragraph}>
        By creating an account on [WebApp Name], you agree to comply with and be
        bound by these Terms and Conditions and our Privacy Policy. If you do
        not agree to these terms, please do not use our services.
      </p>
      <h2 style={styles.subheading}>2. Services Provided</h2>
      <p style={styles.paragraph}>
        [WebApp Name] offers an application tracking system designed for
        recruiters. Our services include:
      </p>
      <ul>
        <li>Posting job openings</li>
        <li>Receiving and managing job applications</li>
        <li>
          Providing relevancy scores for applicants based on their
          qualifications
        </li>
        <li>
          Providing credibility scores based on applicants’ backgrounds and
          references
        </li>
      </ul>
      <h2 style={styles.subheading}>3. Account Registration</h2>
      <p style={styles.paragraph}>
        To use our services, recruiters must create an account. You agree to:
      </p>
      <ul>
        <li>
          Provide accurate and complete information during the registration
          process
        </li>
        <li>
          Maintain and promptly update your information to keep it accurate and
          complete
        </li>
        <li>
          Keep your login credentials confidential and not share them with any
          third parties
        </li>
      </ul>
      <h2 style={styles.subheading}>4. Use of Services</h2>
      <p style={styles.paragraph}>As a recruiter, you agree to:</p>
      <ul>
        <li>
          Use our platform only for lawful purposes and in accordance with these
          terms
        </li>
        <li>
          Not post any job openings that are misleading, inaccurate, or
          inappropriate
        </li>
        <li>
          Respect the privacy and personal data of all applicants as per our
          Privacy Policy
        </li>
        <li>
          Not use the information obtained through our platform for any
          unauthorized purposes
        </li>
      </ul>
      <h2 style={styles.subheading}>5. Relevancy and Credibility Scores</h2>
      <p style={styles.paragraph}>
        [WebApp Name] provides relevancy and credibility scores for applicants.
        These scores are based on:
      </p>
      <ul>
        <li>
          The relevance of an applicant's qualifications to the job requirements
        </li>
        <li>The credibility of an applicant's background and references</li>
      </ul>
      <p style={styles.paragraph}>
        You understand and agree that these scores are provided for
        informational purposes only and do not guarantee any hiring outcomes.
      </p>
      <h2 style={styles.subheading}>6. Prohibited Activities</h2>
      <p style={styles.paragraph}>You agree not to:</p>
      <ul>
        <li>
          Engage in any activity that disrupts or interferes with our services
        </li>
        <li>Use our platform to post false or misleading information</li>
        <li>
          Attempt to gain unauthorized access to other users' accounts or our
          system
        </li>
      </ul>
      <h2 style={styles.subheading}>7. Termination of Account</h2>
      <p style={styles.paragraph}>
        We reserve the right to terminate or suspend your account at our sole
        discretion, without prior notice, for conduct that we believe violates
        these terms or is harmful to other users of the platform.
      </p>
      <h2 style={styles.subheading}>8. Limitation of Liability</h2>
      <p style={styles.paragraph}>
        [WebApp Name] is not responsible for any direct, indirect, incidental,
        consequential, or punitive damages arising out of your use of our
        services. Our liability is limited to the maximum extent permitted by
        law.
      </p>
      <h2 style={styles.subheading}>9. Changes to Terms</h2>
      <p style={styles.paragraph}>
        We may update these Terms and Conditions from time to time. We will
        notify you of any changes by posting the new terms on our platform. Your
        continued use of the services after such changes signifies your
        acceptance of the updated terms.
      </p>
      <h2 style={styles.subheading}>10. Governing Law</h2>
      <p style={styles.paragraph}>
        These terms are governed by and construed in accordance with the laws of
        [Your Jurisdiction], without regard to its conflict of law principles.
      </p>
      <h2 style={styles.subheading}>11. Contact Us</h2>
      <p style={styles.paragraph}>
        If you have any questions or concerns about these Terms and Conditions,
        please contact us at [Contact Information].
      </p>
      <p style={styles.paragraph}>
        By creating an account, you acknowledge that you have read, understood,
        and agree to be bound by these Terms and Conditions.
      </p>
    </div>
  );
};

export default TermsAndConditions;
