import React from 'react';
import styles from './Loader.module.css';

const Loader = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className={styles.loader}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default Loader;
