import React, { useState, useEffect } from 'react';
import styles from './Toast.module.css';
const Toast = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      if (onClose) onClose();
    }, 3000); // Auto-hide after 3 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!isVisible) return null;

  return (
    <div
      className={`${styles.toast} ${
        type === 'error' ? styles.toastError : styles.toastSuccess
      }`}>
      {message}
    </div>
  );
};

export default Toast;
