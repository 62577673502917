import { useState, useEffect, useRef } from 'react';

import DropdownComponentMultiple from '../DropdownMultiple/DropdownMultiple';
import DropdownComponent from '../Dropdown/Dropdown';
import useMultipleClickOutside from '../../hooks/useClickOutside';
import { iconSuccessBlank, iconPointDown, iconSuccessFull } from '../../assets';
import styles from './Criteria.module.css';

function transformArrayToObject(array) {
  return array.map((item) => ({
    name: item,
    value: item,
  }));
}

const filterKeysWithForLabel = (data, label) => {
  const result = {};
  for (const key in data) {
    if (key.includes(label)) {
      result[key] = data[key];
    }
  }
  return result;
};

const Criteria = ({
  forLabel,
  register,
  setCurrentDropdownStates,
  currentDropdownStates,
  priorityObj,
  setValue,
  getValues,
  watch,
  nameToShow,
  preferences,
  type,
  defaultValues,
}) => {
  useEffect(() => {
    const filteredValues = filterKeysWithForLabel(defaultValues, forLabel);
    for (const key in filteredValues) {
      setValue(key, filteredValues[key]);
    }
  }, [defaultValues, forLabel, setValue]);

  const dropdownRefs = {
    [`${forLabel}_preference`]: {
      ref: useRef(),
      id: `#search${forLabel}_preference`,
      ignoreId: `${forLabel}_preference`,
    },
    [`${forLabel}_priority`]: {
      ref: useRef(),
      id: `#search${forLabel}_priority`,
      ignoreId: `${forLabel}_priority`,
    },
  };

  useMultipleClickOutside(
    Object.keys(dropdownRefs).map((key) => ({
      ref: dropdownRefs[key]['ref'],
      callback: () => setCurrentDropdownStates(''),
      ignoreId: key,
    }))
  );

  useEffect(() => {
    const dropdown = dropdownRefs[currentDropdownStates];
    if (dropdown && dropdown.ref.current) {
      const inputField = dropdown.ref.current.querySelector(dropdown.id);
      if (inputField) {
        inputField.focus();
      }
    }
  }, [currentDropdownStates]);

  const [selectedIndices, setSelectedIndices] = useState({
    [`${forLabel}_preference`]: 0,
    [`${forLabel}_priority`]: 0,
  });

  const hasNonEmptyValue = () => {
    const keyNames = [
      `${forLabel}_preference`,
      `${forLabel}_Min_preference`,
      `${forLabel}_Max_preference`,
    ];
    const values = watch();
    return keyNames.some((key) => {
      const value = values[key];
      return (
        (Array.isArray(value) && value.length > 0) ||
        (typeof value === 'string' && value.trim() !== '')
      );
    });
  };

  return (
    <div className={styles.contentInnerWrapper}>
      <div className={styles.contentInner}>
        {hasNonEmptyValue() ? (
          <img
            className={styles.contentImg}
            src={iconSuccessFull}
            alt={'tick full'}
          />
        ) : (
          <img
            className={styles.contentImg}
            src={iconSuccessBlank}
            alt={'tick blank'}
          />
        )}
        <span className={styles.contentTitleText}>{nameToShow}</span>
        <span className={styles.contentCaptionText}>
          <span>Type: </span>
          <span>Multiple choice</span>
        </span>
      </div>
      <div className={styles.dropdownWrapper}>
        {type === 'multiChoice' && (
          <div className={styles.dropdownRowWrapper}>
            <span className={styles.dropdownText}>Choose Preference</span>
            <div style={{ position: 'relative' }}>
              <input
                className={styles.inputFieldWrap}
                id={`${forLabel}_preference`}
                {...register(`${forLabel}_preference`)}
                placeholder='Select priority'
                readOnly
                onClick={() => {
                  setSelectedIndices((prevState) => ({
                    ...prevState,
                    [`${forLabel}Index_preference`]: 0,
                  }));
                  setCurrentDropdownStates((prevState) =>
                    prevState === `${forLabel}_preference`
                      ? ''
                      : `${forLabel}_preference`
                  );
                }}
                autoComplete='off'
              />
              <img
                src={iconPointDown}
                alt='pointDown'
                style={{
                  position: 'absolute',
                  right: '1.6rem',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
              <DropdownComponentMultiple
                currentDropdownState={currentDropdownStates}
                dropdownRef={dropdownRefs[`${forLabel}_preference`]['ref']}
                items={transformArrayToObject(preferences)}
                selectedIndices={selectedIndices}
                dropdownStateName={`${forLabel}_preference`}
                dropdownId={`search${forLabel}_preference`}
                indexName={`${forLabel}Index_preference`}
                setValue={setValue}
                setSelectedIndices={setSelectedIndices}
                getValues={getValues}
                values={watch(`${forLabel}_preference`)}
              />
            </div>
          </div>
        )}

        {type === 'minMax' && (
          <div className={styles.dropdownRowWrapper}>
            <span className={styles.dropdownText}>Choose Preference</span>
            <div style={{ flex: '1' }}>
              <label
                htmlFor={`${forLabel}_Min_preference`}
                className={styles.labelTitle}>
                Minimum
              </label>
              <div style={{ position: 'relative' }}>
                <input
                  className={styles.inputFieldWrap}
                  id={`${forLabel}_Min_preference`}
                  {...register(`${forLabel}_Min_preference`)}
                  placeholder='Minimum'
                />
              </div>
            </div>
            <div style={{ flex: '1' }}>
              <label
                htmlFor={`${forLabel}_Max_preference`}
                className={styles.labelTitle}>
                Maximum
              </label>
              <div style={{ position: 'relative' }}>
                <input
                  className={styles.inputFieldWrap}
                  id={`${forLabel}_Max_preference`}
                  {...register(`${forLabel}_Max_preference`)}
                  placeholder='Maximum'
                />
              </div>
            </div>
          </div>
        )}

        {type === 'min' && (
          <div className={styles.dropdownRowWrapper}>
            <span className={styles.dropdownText}>Choose Preference</span>
            <div style={{ flex: '1' }}>
              <label
                htmlFor={`${forLabel}_Min_preference`}
                className={styles.labelTitle}>
                Minimum
              </label>
              <div style={{ position: 'relative' }}>
                <input
                  className={styles.inputFieldWrap}
                  id={`${forLabel}_Min_preference`}
                  {...register(`${forLabel}_Min_preference`)}
                  placeholder='Minimum'
                />
              </div>
            </div>
          </div>
        )}

        {type === 'max' && (
          <div className={styles.dropdownRowWrapper}>
            <span className={styles.dropdownText}>Choose Preference</span>
            <div style={{ flex: '1' }}>
              <label
                htmlFor={`${forLabel}_Max_preference`}
                className={styles.labelTitle}>
                Maximum
              </label>
              <div style={{ position: 'relative' }}>
                <input
                  className={styles.inputFieldWrap}
                  id={`${forLabel}_Max_preference`}
                  {...register(`${forLabel}_Max_preference`)}
                  placeholder='Maximum'
                />
              </div>
            </div>
          </div>
        )}

        {type === 'singleChoice' && (
          <div className={styles.dropdownRowWrapper}>
            <span className={styles.dropdownText}>Choose Preference</span>
            <div style={{ position: 'relative' }}>
              <input
                className={styles.inputFieldWrap}
                id={`${forLabel}_preference`}
                {...register(`${forLabel}_preference`)}
                placeholder='Select preference'
                readOnly
                onClick={() => {
                  setSelectedIndices((prevState) => ({
                    ...prevState,
                    [`${forLabel}_preference`]: 0,
                  }));
                  setCurrentDropdownStates((prevState) =>
                    prevState === `${forLabel}_preference`
                      ? ''
                      : `${forLabel}_preference`
                  );
                }}
                autoComplete='off'
              />
              <img
                src={iconPointDown}
                alt='pointDown'
                style={{
                  position: 'absolute',
                  right: '1.6rem',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
              <DropdownComponent
                currentDropdownState={currentDropdownStates}
                dropdownRef={dropdownRefs[`${forLabel}_preference`]['ref']}
                items={transformArrayToObject(preferences)}
                selectedIndices={selectedIndices}
                setValue={setValue}
                setCurrentDropdownState={setCurrentDropdownStates}
                dropdownStateName={`${forLabel}_preference`}
                dropdownId={`search${forLabel}_preference`}
                indexName=' '
                setSelectedIndices={setSelectedIndices}
                getValues={getValues}
              />
            </div>
          </div>
        )}
        <div className={styles.dropdownRowWrapper}>
          <span className={styles.dropdownText}>Priority</span>
          <div style={{ position: 'relative' }}>
            <input
              className={styles.inputFieldWrap}
              id={`${forLabel}_priority`}
              {...register(`${forLabel}_priority`)}
              placeholder='Select priority'
              readOnly
              onClick={() => {
                setSelectedIndices((prevState) => ({
                  ...prevState,
                  [`${forLabel}_priority`]: 0,
                }));
                setCurrentDropdownStates((prevState) =>
                  prevState === `${forLabel}_priority`
                    ? ''
                    : `${forLabel}_priority`
                );
              }}
              autoComplete='off'
            />
            <img
              src={iconPointDown}
              alt='pointDown'
              style={{
                position: 'absolute',
                right: '1.6rem',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            />
            <DropdownComponent
              currentDropdownState={currentDropdownStates}
              dropdownRef={dropdownRefs[`${forLabel}_priority`]['ref']}
              items={priorityObj}
              selectedIndices={selectedIndices}
              setValue={setValue}
              setCurrentDropdownState={setCurrentDropdownStates}
              dropdownStateName={`${forLabel}_priority`}
              dropdownId={`search${forLabel}_priority`}
              indexName=' '
              setSelectedIndices={setSelectedIndices}
              getValues={getValues}
            />
          </div>
        </div>
        <div className={styles.dropdownRowWrapper}>
          <span className={styles.dropdownText}>Mandatory</span>

          <div className={styles.toggleWrapper}>
            <span>no</span>
            <input
              className={styles['mz-switch-rounded']}
              type='checkbox'
              {...register(`${forLabel}_isMandatory`)}
              defaultChecked={false}
              name={`${forLabel}_isMandatory`}
              id={`${forLabel}_isMandatory`}
            />
            <label htmlFor={`${forLabel}_isMandatory`}></label>
            <span>yes</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Criteria;
