const handleDownload = async (fileContentUrl) => {
  try {
    const response = await fetch(fileContentUrl);

    const contentType =
      response.headers.get('Content-Type') || 'application/octet-stream';

    const urlParts = fileContentUrl.split('/');
    let filename = urlParts[urlParts.length - 1];
    filename = filename.replace(/\.[^.]+\./, '.');
    let blob;
    if (contentType.includes('text') || contentType.includes('json')) {
      const text = await response.text();
      blob = new Blob([text], { type: contentType });
    } else {
      const arrayBuffer = await response.arrayBuffer();
      blob = new Blob([arrayBuffer], { type: contentType });
    }

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export default handleDownload;
