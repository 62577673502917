const keyMapping = {
  educationalQualification: {
    fieldName: 'Educational Qualifications',
    options: ['Bachelors', 'Masters', 'PhD', 'Others'],
    type: 'multiChoice',
    applyType: 'singleChoice',
  },
  totalYearsEfExperience: {
    fieldName: 'Total Years of Experience',
    options: null,
    type: 'minMax',
    applyType: 'inputNum',
  },
  fieldOfStudy: {
    fieldName: 'Field of Study',
    options: [
      'Computer Science',
      'Mechanical Engineering',
      'Electrical Engineering',
      'Civil Engineering',
      'Biomedical Engineering',
      'Chemical Engineering',
      'Data Science',
      'Physics',
      'Mathematics',
      'Statistics',
      'Economics',
      'Business Administration',
      'Finance',
      'Marketing',
      'Psychology',
      'Biology',
      'Medicine',
      'Law',
      'Political Science',
      'Sociology',
      'Philosophy',
      'Anthropology',
      'Art History',
      'Linguistics',
      'Architecture',
      'Environmental Science',
      'Journalism',
      'Education',
      'Nursing',
      'Agriculture',
      'Geology',
      'Chemistry',
      'History',
      'International Relations',
      'Public Health',
      'Software Engineering',
      'Astronomy',
      'Robotics',
      'Human Resources',
      'Supply Chain Management',
      'Information Systems',
      'Graphic Design',
      'Theology',
      'Physics Engineering',
      'Veterinary Science',
      'Pharmacy',
      'Nutrition',
      'Sports Science',
      'Materials Science',
      'Marine Biology',
      'Aerospace Engineering',
      'Food Science',
    ],
    type: 'multiChoice',
    applyType: 'singleChoice',
  },
  tierOfInstitute: {
    fieldName: 'College Tier',
    options: ['Tier 1', 'Tier 2', 'Tier 3'],
    type: 'multiChoice',
    applyType: 'singleChoice',
  },
  grades: {
    fieldName: 'GPA',
    options: null,
    type: 'min',
    applyType: 'inputNum',
  },
  leadershipExp: {
    fieldName: 'Leadership Experience (in yrs.)',
    options: null,
    type: 'minMax',
    applyType: 'inputNum',
  },
  managementExp: {
    fieldName: 'Management Experience (in yrs.)',
    options: null,
    type: 'minMax',
    applyType: 'inputNum',
  },
  languagesKnown: {
    fieldName: 'Languages known',
    options: [
      'English',
      'Mandarin Chinese',
      'Spanish',
      'Hindi',
      'Arabic',
      'Portuguese',
      'Bengali',
      'Russian',
      'Japanese',
      'Punjabi',
      'German',
      'Javanese',
      'Korean',
      'French',
      'Turkish',
      'Vietnamese',
      'Italian',
      'Urdu',
      'Persian',
      'Thai',
      'Gujarati',
      'Polish',
      'Ukrainian',
      'Romanian',
      'Dutch',
      'Greek',
      'Czech',
      'Swedish',
      'Hungarian',
      'Hebrew',
      'Danish',
      'Norwegian',
      'Finnish',
      'Slovak',
      'Croatian',
      'Serbian',
      'Malay',
      'Swahili',
      'Zulu',
      'Afrikaans',
      'Filipino',
      'Tamil',
      'Telugu',
      'Kannada',
      'Marathi',
      'Burmese',
      'Khmer',
      'Lao',
      'Sinhala',
      'Pashto',
      'Kurdish',
      'Armenian',
      'Georgian',
      'Nepali',
      'Mongolian',
      'Albanian',
      'Bosnian',
      'Estonian',
      'Latvian',
      'Lithuanian',
      'Irish',
      'Welsh',
      'Icelandic',
      'Maltese',
    ],
    type: 'multiChoice',
    applyType: 'multiChoice',
  },
  noticePeriod: {
    fieldName: 'Notice Period (in days)',
    options: null,
    type: 'max',
    applyType: 'inputNum',
  },
  expectedSalary: {
    fieldName: 'Salary Expectation (in LPA)',
    options: null,
    type: 'minMax',
    applyType: 'inputNum',
  },
  willingnessToTravel: {
    fieldName: 'Willingness to Travel',
    options: ['Yes', 'No'],
    type: 'singleChoice',
    applyType: 'singleChoice',
  },
  gender: {
    fieldName: 'Gender',
    options: ['Male', 'Female', 'Other'],
    type: 'multiChoice',
    applyType: 'singleChoice',
  },
  ethnicGroup: {
    fieldName: 'Ethnic Group',
    options: [
      'White',
      'Hispanic or Latino',
      'Black or African American',
      'Native American or Alaska Native',
      'Asian',
      'Native Hawaiian or Other Pacific Islander',
      'Middle Eastern or North African',
      'Multiracial',
      'South Asian',
      'East Asian',
      'Southeast Asian',
      'Caribbean',
      'African',
      'Hispanic American',
      'Latino American',
      'European',
      'Arab',
      'Persian',
      'Jewish',
      'Indian',
      'Pakistani',
      'Bangladeshi',
      'Sri Lankan',
      'Filipino',
      'Korean',
      'Japanese',
      'Chinese',
      'Vietnamese',
      'Thai',
      'Hmong',
      'Cambodian',
      'Burmese',
      'Polynesian',
      'Micronesian',
      'Melanesian',
    ],
    type: 'multiChoice',
    applyType: 'singleChoice',
  },
  physicalChallenges: {
    fieldName: 'Physical Challenges',
    options: ['Yes', 'No'],
    type: 'singleChoice',
    applyType: 'singleChoice',
  },
  industryType: {
    fieldName: 'Industry Type',
    options: [
      'Agriculture',
      'Automotive',
      'Banking',
      'Biotechnology',
      'Construction',
      'Consumer Goods',
      'Education',
      'Energy',
      'Entertainment',
      'Finance',
      'Food & Beverage',
      'Healthcare',
      'Hospitality',
      'Insurance',
      'Information Technology',
      'Legal',
      'Manufacturing',
      'Media',
      'Mining',
      'Non-profit',
      'Pharmaceuticals',
      'Real Estate',
      'Retail',
      'Telecommunications',
      'Transportation',
      'Utilities',
    ],
    type: 'singleChoice',
    applyType: 'singleChoice',
  },
};

const clientServerKeyMap = {
  educationalQualification_preference: 'educational_qualifications',
  fieldOfStudy_preference: 'field_of_study',
  tierOfInstitute_preference: 'college_tier',
  grades_Min_preference: 'min_gpa',
  totalYearsEfExperience_Min_preference: 'min_years_exp',
  totalYearsEfExperience_Max_preference: 'max_years_exp',
  leadershipExp_Min_preference: 'min_leadership_exp',
  leadershipExp_Max_preference: 'max_leadership_exp',
  managementExp_Min_preference: 'min_management_exp',
  managementExp_Max_preference: 'max_management_exp',
  languagesKnown_preference: 'language_proficiency',
  noticePeriod_Max_preference: 'notice_period',
  expectedSalary_Min_preference: 'min_salary_expectation',
  expectedSalary_Max_preference: 'max_salary_expectations',
  willingnessToTravel_preference: 'willingness_to_travel',
  gender_preference: 'gender',
  ethnicGroup_preference: 'ethnic_group',
  physicalChallenges_preference: 'disability',
  industryType_preference: 'industry_type',
};

const clientServerKeyApplyMap = {
  educationalQualification_preference: 'educational_qualifications',
  fieldOfStudy_preference: 'field_of_study',
  tierOfInstitute_preference: 'college_tier',
  grades_preference: 'min_gpa',
  totalYearsEfExperience_preference: 'years_exp',
  leadershipExp_preference: 'leadership_exp',
  managementExp_preference: 'management_exp',
  languagesKnown_preference: 'language_proficiency',
  noticePeriod_preference: 'notice_period',
  expectedSalary_preference: 'salary_expectation',
  willingnessToTravel_preference: 'willingness_to_travel',
  gender_preference: 'gender',
  ethnicGroup_preference: 'ethnic_group',
  physicalChallenges_preference: 'disability',
  industryType_preference: 'industry_type',
};

const serverToKeyMap1 = {
  educational_qualifications: 'educationalQualification',
  field_of_study: 'fieldOfStudy',
  college_tier: 'tierOfInstitute',
  min_gpa: 'grades',
  min_years_exp: 'totalYearsEfExperience',
  max_years_exp: 'totalYearsEfExperience',
  min_leadership_exp: 'leadershipExp',
  max_leadership_exp: 'leadershipExp',
  min_management_exp: 'managementExp',
  max_management_exp: 'managementExp',
  language_proficiency: 'languagesKnown',
  notice_period: 'noticePeriod',
  min_salary_expectation: 'expectedSalary',
  max_salary_expectations: 'expectedSalary',
  willingness_to_travel: 'willingnessToTravel',
  gender: 'gender',
  ethnic_group: 'ethnicGroup',
  disability: 'physicalChallenges',
  industry_type: 'industryType',
};
const serverToKeyMap = {
  educational_qualifications: 'educationalQualification',
  field_of_study: 'fieldOfStudy',
  college_tier: 'tierOfInstitute',
  min_gpa: 'grades',
  years_exp: 'totalYearsEfExperience',
  leadership_exp: 'leadershipExp',
  management_exp: 'managementExp',
  language_proficiency: 'languagesKnown',
  notice_period: 'noticePeriod',
  salary_expectation: 'expectedSalary',
  salary_expectations: 'expectedSalary',
  willingness_to_travel: 'willingnessToTravel',
  gender: 'gender',
  ethnic_group: 'ethnicGroup',
  disability: 'physicalChallenges',
  industry_type: 'industryType',
};
export {
  keyMapping,
  clientServerKeyMap,
  serverToKeyMap,
  clientServerKeyApplyMap,
};
