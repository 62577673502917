import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { debounce } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { postSignup, verifyOtp } from '../../redux/submitSignupSlice';
import Loader from '../../components/Loader/Loader';
import styles from './Signup.module.css';
import { imgBackground, logoDark, iconEye, iconMail } from '../../assets';
const errorMap = {
  email_exists:
    'The provided email is already associated with an existing account.',
  invalid_email: 'Please use a valid company email address.',
};
const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { action } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const {
    register,
    formState: { errors },
    getValues,
    watch,
    trigger,
    setError,
  } = useForm({
    defaultValues: {
      company: '',
      email: '',
      name: '',
      password: '',
      otp: '',
      tNcAgree: false,
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('This field is required'),
        email: yup
          .string()
          .required('This field is required')
          .email('Must be a valid email'),
        company: yup.string().required('This field is required'),
        password: yup
          .string()
          .required('This field is required')
          .min(8, 'Password must be at least 8 characters')
          .matches(
            /(?=.*[a-z])/,
            'Password must contain at least one lowercase letter'
          )
          .matches(
            /(?=.*[A-Z])/,
            'Password must contain at least one uppercase letter'
          )
          .matches(/(?=.*\d)/, 'Password must contain at least one number'),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password is required'),
        otp: yup
          .string()
          .required('This field is required')
          .length(6, 'OTP must be exactly 6 digits')
          .matches(/^\d{6}$/, 'OTP must be a number'),
        tNcAgree: yup.bool().oneOf([true], 'You must agree to the terms'),
      })
    ),
  });
  const watchCheckbox = watch('tNcAgree', false);
  const watchName = watch('name', '');
  const watchEmail = watch('email', '');
  const watchCompany = watch('company', '');
  const watchPassword = watch('password', '');
  const watchConfirmPassword = watch('confirmPassword', '');
  const watchOtp = watch('otp', '');

  const isSubmitDisabled =
    !(watchPassword && watchConfirmPassword) ||
    errors.password ||
    errors.confirmPassword ||
    watchPassword !== watchConfirmPassword;

  const isNextDisabled =
    !watchName ||
    errors.name ||
    !watchEmail ||
    errors.email ||
    !watchCompany ||
    errors.company ||
    !watchCheckbox ||
    errors.tNcAgree;

  const getErrorClass = (fieldName) => {
    return errors[fieldName] ? styles.error : '';
  };
  useEffect(() => {
    if (
      action === 'set-password' &&
      (!watchName || !watchEmail || !watchCompany || !watchCheckbox)
    ) {
      navigate('/client/signup/create-account');
    }
  }, [action, watchName, watchEmail, watchCompany, watchCheckbox, navigate]);

  const onSubmit = async () => {
    const data = getValues();
    setShowLoader(true);
    const resultAction = await dispatch(postSignup(data));
    if (resultAction.type === 'signUp/postData/fulfilled') {
      //   reset();
      navigate('/client/signup/enter-otp');
    } else {
      setError('email', {
        type: 'manual',
        message: errorMap[resultAction.payload.message],
      });
      navigate('/client/signup/create-account');
    }
    setShowLoader(false);
  };

  const onSubmitOtp = async () => {
    const { email, otp } = getValues();
    const resultAction = await dispatch(verifyOtp({ otp, email }));
    if (resultAction.type === 'signUp/verifyOtp/fulfilled') {
      //   reset();
      // navigate('/client/login/enter');
      navigate('/client/dashboard');
    }
  };
  const validateAndNavigate = async () => {
    const result = await trigger(['name', 'email', 'company', 'tNcAgree']);
    if (result) {
      navigate('/client/signup/set-password');
    }
  };

  const debouncedTrigger = useRef(
    debounce((trigger, fieldName) => trigger(fieldName), 500)
  ).current;

  return (
    <section className={styles.signup}>
      <div className={styles.column1}>
        <div className={styles.logoWrapper}>
          <img src={logoDark} alt={'logoDark'} />
        </div>
        <img
          className={styles.heroLogo}
          src={imgBackground}
          alt={'background'}
        />
      </div>
      <div className={styles.column2}>
        <form className={styles.form}>
          {action === 'create-account' && (
            <div>
              <h3 className={styles.header}>Create an account</h3>
              <div>
                <label htmlFor='name' className={styles.labelTitle}>
                  Name
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <input
                    className={styles.inputFieldWrap}
                    id='name'
                    {...register('name')}
                    placeholder='Enter name'
                  />
                  {errors.name && <span>{errors.name.message}</span>}
                </div>
              </div>

              <div>
                <label htmlFor='email' className={styles.labelTitle}>
                  Company Email
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <input
                    className={styles.inputFieldWrap}
                    id='email'
                    {...register('email', {
                      onChange: () => debouncedTrigger(trigger, 'email'),
                    })}
                    placeholder='Enter email'
                  />
                  {errors.email && <span>{errors.email.message}</span>}
                </div>
              </div>

              <div>
                <label htmlFor='company' className={styles.labelTitle}>
                  Company Name
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <input
                    className={styles.inputFieldWrap}
                    id='company'
                    {...register('company')}
                    placeholder='Enter company'
                  />
                  {errors.company && <span>{errors.company.message}</span>}
                </div>
              </div>

              <div className={styles.marginBottom + ' ' + styles.tNcWrapper}>
                <input
                  className={`${styles.inputCheckbox} ${
                    watchCheckbox ? styles.inputChecked : styles.inputUnchecked
                  }`}
                  type='checkbox'
                  id='tNcAgree'
                  // checked={watch('tNcAgree')}
                  {...register('tNcAgree', { required: true })}
                />
                <label htmlFor='tNcAgree' className={styles.tNcText}>
                  I agree with{' '}
                  <Link to='/client/tnc' className={styles.textGreen}>
                    Terms & Conditions
                  </Link>
                </label>
                {errors.tNcAgree && <span>{errors.tNcAgree.message}</span>}
              </div>

              <button
                onClick={validateAndNavigate}
                className={`${styles.btnNext} ${
                  isNextDisabled ? styles.disabledBtn : ''
                }`}
                disabled={isNextDisabled}
                type={'button'}>
                {' '}
                next
              </button>
              <div className={styles.line}></div>
              <span className={styles.textBelowLine}>
                Are you an existing user?
              </span>
              <button
                className={styles.loginBtn}
                type={'button'}
                onClick={() => navigate('/client/login/enter')}>
                Log In
              </button>
            </div>
          )}

          {action === 'set-password' && (
            <div>
              <h3 className={styles.header}>Set password</h3>

              <div>
                <label htmlFor='password' className={styles.labelTitle}>
                  Password
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <div className={styles.inputWrapper}>
                    <input
                      className={
                        styles.inputFieldWrap + ' ' + getErrorClass('password')
                      }
                      id='password'
                      {...register('password')}
                      placeholder='Enter password'
                      type={showPassword ? 'text' : 'password'}
                    />
                    <img
                      className={styles.inputIcon}
                      src={iconEye}
                      alt={'iconEye'}
                      onClick={handlePasswordVisibility}
                    />
                  </div>
                  {errors.password && <span>{errors.password.message}</span>}
                </div>
              </div>

              <div>
                <label htmlFor='confirmPassword' className={styles.labelTitle}>
                  Confirm Password
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <div className={styles.inputWrapper}>
                    <input
                      className={
                        styles.inputFieldWrap +
                        ' ' +
                        getErrorClass('confirmPassword')
                      }
                      id='confirmPassword'
                      {...register('confirmPassword')}
                      placeholder='Re-enter Password'
                      type={showPassword ? 'text' : 'password'}
                      // onKeyDown={() => {
                      //   if (watch('password')) {
                      //     trigger('password');
                      //   }
                      //   if (watch('confirmPassword')) {
                      //     trigger('confirmPassword');
                      //   }
                      // }}
                      onBlur={() => {
                        trigger('confirmPassword');
                      }}
                    />
                    <img
                      className={styles.inputIcon}
                      src={iconEye}
                      alt={'iconEye'}
                      onClick={handlePasswordVisibility}
                    />
                  </div>

                  {errors.confirmPassword && (
                    <span>{errors.confirmPassword.message}</span>
                  )}
                </div>
              </div>
              <button
                onClick={onSubmit}
                className={`${styles.btnNext} ${
                  isSubmitDisabled ? styles.disabledBtn : ''
                }`}
                disabled={isSubmitDisabled}
                type={'button'}>
                {' '}
                submit
              </button>
            </div>
          )}

          {action === 'enter-otp' && (
            <div>
              <div className={styles.notiWrapper}>
                <img
                  className={styles.iconMail}
                  src={iconMail}
                  alt={'iconMail'}
                />
                <span className={styles.notiText}>
                  Please check your inbox!
                </span>
              </div>
              <h3 className={styles.header}>Enter OTP sent on Email</h3>

              <div>
                <label htmlFor='otp' className={styles.labelTitle}>
                  OTP
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <input
                    className={styles.inputFieldWrap}
                    id='otp'
                    {...register('otp')}
                    placeholder='Enter 6-digit OTP'
                  />
                  {errors.otp && <span>This field is required</span>}
                </div>
              </div>
              <button
                onClick={onSubmitOtp}
                className={`${styles.btnNext} ${
                  watchOtp.length !== 6 || isNaN(watchOtp)
                    ? styles.disabledBtn
                    : ''
                }`}
                disabled={watchOtp.length !== 6 || isNaN(watchOtp)}
                type={'button'}>
                {' '}
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
      <Loader isLoading={showLoader} />
    </section>
  );
};

export default Signup;
