// features/toast/toastSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    isVisible: false,
    message: '',
    type: 'info', // default type
  },
  reducers: {
    showToast: (state, action) => {
      state.isVisible = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    hideToast: (state) => {
      state.isVisible = false;
      state.message = '';
      state.type = 'info';
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
