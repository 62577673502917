import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { APPLY_API } from '../apiEndpoints';
import { openModal, closeModal } from '../redux/submitModalSlice';

function replaceEmptyValues(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      replaceEmptyValues(obj[key]);
    } else if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      obj[key] = 'N/A';
    }
  });
}

export const fetchApplyData = createAsyncThunk(
  'apply/fetchData',
  async (queryParams) => {
    const response = await axios.get(APPLY_API, {
      params: queryParams,
    });

    replaceEmptyValues(response);
    return { ...response.data };
  }
);

export const postApplyData = createAsyncThunk(
  'apply/postData',
  async (jobData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: 'post',
        url: APPLY_API,
        data: jobData,
        headers: { 'Content-Type': 'application/json' },
      });
      dispatch(openModal({ message: 'Applied successfully!' }));
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const applySlice = createSlice({
  name: 'apply',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
    postApplyDataStatus: 'idle',
    postApplyDataError: null,
    postApplyData: {},
  },
  reducers: {
    saveDataapply: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplyData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchApplyData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchApplyData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(postApplyData.pending, (state) => {
        state.postApplyDataStatus = 'loading';
      })
      .addCase(postApplyData.fulfilled, (state, action) => {
        state.postApplyDataStatus = 'succeeded';
        state.postApplyData = action.payload;
        state.postApplyDataError = null;
      })
      .addCase(postApplyData.rejected, (state, action) => {
        state.postApplyDataStatus = 'failed';
        state.postApplyDataError = action.error.message;
      });
  },
});
export const { saveDataapply } = applySlice.actions;
export default applySlice.reducer;
