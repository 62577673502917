import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalSkeleton from '../ModalSkeleton/ModalSkeleton';
import { useForm } from 'react-hook-form';

import { iconCross } from '../../assets';
import styles from './CustomCriteria.module.css';
import Criteria from '../Criteria/Criteria';

import { keyMapping } from '../../constants/customCriteriaMap';

const priorityObj = [
  {
    value: '3',
    name: 'High',
  },
  {
    value: '2',
    name: 'Mid',
  },
  {
    value: '1',
    name: 'Low',
  },
  {
    value: '0',
    name: 'No preference',
  },
];

function filterPreferences(data) {
  const result = {};
  for (const key in data) {
    if (
      key.endsWith('_preference') &&
      data[key] !== '' &&
      (!Array.isArray(data[key]) || data[key].length > 0)
    ) {
      const label = key.split('_')[0];
      result[key] = data[key];
      for (const subKey in data) {
        if (subKey.startsWith(`${label}_`) && !(subKey in result)) {
          result[subKey] = data[subKey];
        }
      }
    }
  }
  return result;
}

const CustomCriteria = ({
  setOpenCustomCreteria,
  setValueParentForm,
  defaultValues,
}) => {
  const [currentDropdownStates, setCurrentDropdownStates] = useState('');
  const [currentFilter, setCurrentFilter] = useState('showAll');
  const [selectedCount, setSelectedCount] = useState(0);
  const [unselectedCount, setUnselectedCount] = useState(0);
  const [filteredContent, setFilteredContent] = useState({
    showAll: [],
    showSelected: [],
    showUnselected: [],
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      educationalQualification_preference: [],
      languagesKnown_preference: [],
      tierOfInstitute_preference: [],
      fieldOfStudy_preference: [],
    },
  });

  const filterContent = () => {
    const result = {
      showAll: [],
      showSelected: [],
      showUnselected: [],
    };

    Object.keys(keyMapping).forEach((item) => {
      result.showAll.push(item);
    });

    Object.keys(keyMapping).forEach((item) => {
      const preferenceValue = getPreferenceValue(item);
      if (
        (preferenceValue !== null &&
          typeof preferenceValue === 'string' &&
          preferenceValue !== '') ||
        (Array.isArray(preferenceValue) && preferenceValue.length > 0)
      ) {
        result.showSelected.push(item);
      }
    });

    Object.keys(keyMapping).forEach((item) => {
      const preferenceValue = getPreferenceValue(item);

      if (
        preferenceValue === null ||
        (typeof preferenceValue === 'string' && preferenceValue === '') ||
        (Array.isArray(preferenceValue) && preferenceValue.length === 0)
      ) {
        result.showUnselected.push(item);
      }
    });

    return result;
  };

  useEffect(() => {
    setSelectedCount(countSelected());
    setUnselectedCount(countUnselected());
    setFilteredContent(filterContent());
  }, []);

  const commonProps = {
    register,
    setCurrentDropdownStates,
    currentDropdownStates,
    priorityObj,
    setValue,
    getValues,
    watch,
    defaultValues,
  };

  const onSubmit = async (data) => {
    const values = filterPreferences(data);
    setValueParentForm('criteria', values);
    setOpenCustomCreteria(false);
  };

  const handleCurrentFilter = (filterToChange) => {
    setCurrentFilter(filterToChange);
    console.log(getValues());
  };

  const getPreferenceValue = (item) => {
    const values = defaultValues || {};
    const key = Object.keys(values).find(
      (key) => key.startsWith(item) && key.endsWith('_preference')
    );
    return key ? values[key] : null;
  };

  const countSelected = () => {
    return Object.keys(keyMapping).filter((item) => {
      const preferenceValue = getPreferenceValue(item);
      return (
        (preferenceValue !== null &&
          typeof preferenceValue === 'string' &&
          preferenceValue !== '') ||
        (Array.isArray(preferenceValue) && preferenceValue.length > 0)
      );
    }).length;
  };

  const countUnselected = () => {
    return Object.keys(keyMapping).filter((item) => {
      const preferenceValue = getPreferenceValue(item);
      return (
        preferenceValue === null ||
        (typeof preferenceValue === 'string' && preferenceValue === '') ||
        (Array.isArray(preferenceValue) && preferenceValue.length === 0)
      );
    }).length;
  };

  return (
    <ModalSkeleton>
      <div className={styles.wrapper}>
        <div className={styles.modalContent}>
          <div className={styles.headerWrapper}>
            <span className={styles.headerText}>
              Add/Edit Custom Job Criteria
            </span>
            <img
              className={styles.closeIcon}
              src={iconCross}
              alt='cross'
              onClick={() => {
                setOpenCustomCreteria(false);
              }}
            />
          </div>
          <div className={styles.filterTextWrapper}>
            <span
              className={`${styles.filterText} ${
                currentFilter === 'showAll' ? styles.highlight : ''
              }`}
              onClick={() => handleCurrentFilter('showAll')}>
              Show all
            </span>
            <span
              className={`${styles.filterText} ${
                currentFilter === 'showSelected' ? styles.highlight : ''
              }`}
              onClick={() => handleCurrentFilter('showSelected')}>
              Show selected ({selectedCount})
            </span>
            <span
              className={`${styles.filterText} ${
                currentFilter === 'showUnselected' ? styles.highlight : ''
              }`}
              onClick={() => handleCurrentFilter('showUnselected')}>
              Show unselected ({unselectedCount})
            </span>
          </div>
          <div className={styles.contentWrapper}>
            {filteredContent[currentFilter].map((key) => {
              const value = keyMapping[key];
              return (
                <Criteria
                  key={key}
                  forLabel={key}
                  nameToShow={value['fieldName']}
                  preferences={value['options']}
                  type={value['type']}
                  {...commonProps}
                />
              );
            })}
          </div>
          <div className={styles.submitButton}>
            <button type='button' className={styles.btnCancel}>
              Cancel
            </button>
            <button
              onClick={handleSubmit(onSubmit)}
              className={styles.btnSubmit}
              type='button'>
              Save
            </button>
          </div>
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default CustomCriteria;
