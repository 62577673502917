import { useEffect } from 'react';

const useMultipleClickOutside = (refsAndCallbacks) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      refsAndCallbacks.forEach(({ ref, callback, ignoreId }) => {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          event.target.id !== ignoreId
        ) {
          callback();
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refsAndCallbacks]);
};

export default useMultipleClickOutside;
