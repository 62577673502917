import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalSkeleton from '../ModalSkeleton/ModalSkeleton';
import { useForm } from 'react-hook-form';
import { closeModal, postBulkUploadData } from '../../redux/bulkUpload';
import candidatesTrueHire from '../../files/candidatesTrueHire.csv';
import candidatesSpringRecruit from '../../files/candidatesSpringRecruit.csv';
import resumesZip from '../../files/resumes.zip';
import downloadFile from '../../utils/downloadFile';
import useMultipleClickOutside from '../../hooks/useClickOutside';
import DropdownComponent from '../../components/Dropdown/Dropdown';

import {
  iconCross,
  iconSpring,
  iconTrue,
  iconArrowDark,
  iconPointDown,
  iconUploadBack,
  iconDownload,
  iconEdit,
  iconSuccess,
  iconIncorrect,
} from '../../assets';
import styles from './BulkUpload.module.css';

const companyFiles = {
  'Spring Recruit': candidatesSpringRecruit,
  'True Hire': candidatesTrueHire,
};

const priorityObj = [
  {
    value: 'trueHire',
    name: 'True Hire',
  },
  {
    value: 'springRecruit',
    name: 'Spring Recruit',
  },
];

const BulkUpload = () => {
  const navigate = useNavigate();
  const { isOpen, jobId, uploadProgress } = useSelector(
    (state) => state.bulkUpload
  );
  const [currentScreen, setCurrentScreen] = useState('screen1');
  const [currentDropdownStates, setCurrentDropdownStates] = useState('');
  const [currentCompany, setCurrentCompany] = useState('');
  const [selectedFile, setSelectedFile] = useState({
    csv: {
      file: null,
      error: null,
    },
    zip: {
      file: null,
      error: null,
    },
  });

  const [isDragging, setIsDragging] = useState(false);
  const [progress, setProgress] = useState(0);

  const canvasRef = useRef(null);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({ defaultValues: { company: '' } });

  const dropdownRefs = {
    priorityMinExp: {
      ref: useRef(),
      id: '#searchPriorityMinExp',
      ignoreId: 'priorityMinExp',
    },
  };

  useMultipleClickOutside(
    Object.keys(dropdownRefs).map((key) => ({
      ref: dropdownRefs[key]['ref'],
      callback: () => setCurrentDropdownStates(''),
      ignoreId: key,
    }))
  );

  useEffect(() => {
    const dropdown = dropdownRefs[currentDropdownStates];
    if (dropdown && dropdown.ref.current) {
      const inputField = dropdown.ref.current.querySelector(dropdown.id);
      if (inputField) {
        inputField.focus();
      }
    }
  }, [currentDropdownStates]);

  const [selectedIndices, setSelectedIndices] = useState({
    priorityMinExpIndex: 0,
  });
  const handleFileAddition = (file) => {
    if (currentScreen === 'screen2') {
      if (file && file.type === 'text/csv') {
        setSelectedFile((prev) => {
          return {
            ...prev,
            csv: {
              file,
              error: null,
            },
          };
        });
      } else {
        setSelectedFile((prev) => {
          return {
            ...prev,
            csv: {
              file: null,
              error: 'Please upload a valid csv file.',
            },
          };
        });
      }
    }

    if (currentScreen === 'screen3') {
      if (file && file.type === 'application/zip') {
        setSelectedFile((prev) => {
          return {
            ...prev,
            zip: {
              file,
              error: null,
            },
          };
        });
      } else {
        setSelectedFile((prev) => {
          return {
            ...prev,
            zip: {
              file: null,
              error: 'Please upload a valid zip file with resumes.',
            },
          };
        });
      }
    }
  };
  const handleCompanyClick = (company) => {
    setCurrentCompany(company);
    setCurrentScreen('screen2');
    setValue('company', company);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    handleFileAddition(file);
  };

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (!file) return;
    handleFileAddition(file);
  };

  const handleFileSubmit = async () => {
    const readFileAsBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
      });
    try {
      const base64StringCsv = await readFileAsBase64(selectedFile.csv.file);
      let base64StringZip = '';
      if (selectedFile.zip.file) {
        base64StringZip = await readFileAsBase64(selectedFile.zip.file);
      }

      const newObj = {
        csv: base64StringCsv,
        zip: base64StringZip,
        job_id: jobId,
        format: currentCompany,
      };

      const resultAction = await dispatch(postBulkUploadData(newObj));

      if (resultAction.type === 'bulkUploadModal/postData/fulfilled') {
        reset();
        setCurrentScreen('screen4');
      }
      if (resultAction.type === 'bulkUploadModal/postData/rejected') {
        setCurrentScreen('screen5');
      }
    } catch (error) {
      console.error('Error reading file:', error);
    }

    // const formData = new FormData();
    // formData.append('file', file);

    // axios
    //   .post('/bulk-upload', formData, {
    //     onUploadProgress: (progressEvent) => {
    //       const percentCompleted = Math.round(
    //         (progressEvent.loaded * 100) / progressEvent.total
    //       );
    //       setProgress(percentCompleted);
    //     },
    //   })
    //   .then((response) => {
    //     console.log('File uploaded successfully:', response.data);
    //   })
    //   .catch((error) => {
    //     console.error('Error uploading file:', error);
    //   });
  };

  const handleBack = () => {
    setCurrentScreen((prev) => {
      const screen = `screen${prev.split('').pop() - 1}`;
      if (screen === 'screen1') {
        setSelectedFile({
          csv: {
            file: null,
            error: null,
          },
          zip: {
            file: null,
            error: null,
          },
        });
      }
      return screen;
    });
  };

  const goToApplicants = () => {
    setSelectedFile({
      csv: {
        file: null,
        error: null,
      },
      zip: {
        file: null,
        error: null,
      },
    });
    setCurrentScreen('screen1');
    dispatch(closeModal());
    navigate('/client/applicants');
  };
  if (!isOpen) return null;

  return (
    <ModalSkeleton>
      <div className={styles.wrapper}>
        <div className={styles.modalContent}>
          <div className={styles.headerWrapper}>
            <span className={styles.headerText}>Bulk Upload Applicants</span>
            <img
              className={styles.closeIcon}
              src={iconCross}
              alt='cross'
              onClick={() => {
                setSelectedFile({
                  csv: {
                    file: null,
                    error: null,
                  },
                  zip: {
                    file: null,
                    error: null,
                  },
                });
                setCurrentScreen('screen1');
                dispatch(closeModal());
              }}
            />
          </div>
          {currentScreen === 'screen1' && (
            <>
              <span className={styles.captionText}>{getValues('company')}</span>
              <div className={styles.company}>
                <div
                  className={styles.companyWrapper}
                  onClick={() => handleCompanyClick('True Hire')}>
                  <img src={iconTrue} alt={'truehire'} />
                  <span className={styles.companyText}>TrueHire</span>
                </div>
                <div
                  className={styles.companyWrapper}
                  onClick={() => handleCompanyClick('Spring Recruit')}>
                  <img src={iconSpring} alt={'spring'} />
                  <span className={styles.companyText}>Spring Recruit</span>
                </div>
              </div>
            </>
          )}

          {currentScreen === 'screen2' && (
            <>
              <div className={styles.captionRowWrapper}>
                <div className={styles.captionTextWrapper} onClick={handleBack}>
                  <img src={iconArrowDark} alt={'go back'} />
                  <span className={styles.captionTextUpload}>Upload file</span>
                </div>
                <div className={styles.dropwdownWrapper}>
                  <span className={styles.dropwdownLabel}>template:</span>
                  <div style={{ position: 'relative', width: '21.2rem' }}>
                    <input
                      className={styles.inputFieldWrap}
                      id='priorityMinExp'
                      {...register('priorityMinExp')}
                      placeholder='Select'
                      readOnly
                      defaultValue={getValues('company')}
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          priorityMinExpIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'priorityMinExp' ? '' : 'priorityMinExp'
                        );
                      }}
                      autoComplete='off'
                    />
                    <img
                      src={iconPointDown}
                      alt='pointDown'
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <DropdownComponent
                      currentDropdownState={currentDropdownStates}
                      dropdownRef={dropdownRefs['priorityMinExp']['ref']}
                      items={priorityObj}
                      selectedIndices={selectedIndices}
                      setValue={setValue}
                      setCurrentDropdownState={setCurrentDropdownStates}
                      dropdownStateName='priorityMinExp'
                      dropdownId='searchPriorityMinExp'
                      indexName='priorityMinExpIndex'
                      setSelectedIndices={setSelectedIndices}
                      getValues={getValues}
                    />
                  </div>
                </div>
              </div>

              <div>
                <div
                  className={styles.uploadWrapper + ' ' + styles.marginBottom}>
                  <div
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url('data:image/svg+xml,<svg viewBox="0 0 672 260" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="671" height="259" rx="7.5" fill="%23F5F9FE" stroke="${selectedFile?.csv?.error ? '%23ff5757' : '%23049F7A'
                        }" stroke-dasharray="7 7"/></svg>')`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      opacity: isDragging ? 0.5 : 1,
                      transition: 'opacity 0.3s ease',
                    }}
                    className={styles.marginBottom}>
                    {selectedFile?.csv?.file?.error && (
                      <div className={styles.errorText}>
                        {selectedFile?.csv?.file?.error && (
                          <>
                            <img
                              src={iconIncorrect}
                              alt={'error'}
                              className={styles.errorimg}
                            />
                            {/* <span>{selectedFile?.csv?.file?.error}</span> */}
                            <span>
                              File size is too large. Please upload a file
                              smaller than 25 MB.
                            </span>
                          </>
                        )}
                      </div>
                    )}

                    <input
                      type='file'
                      id='fileInput'
                      style={{ display: 'none' }}
                      accept='text/csv'
                      onChange={handleFileChange}
                    />
                    {selectedFile?.csv?.file?.name ? (
                      <div className={styles.iconWrapper}>
                        <div className={styles.fileUploadedWrapper}>
                          {currentCompany === 'Spring Recruit' ? (
                            <>
                              {uploadProgress > 0 && (
                                <div>
                                  <div
                                    className={
                                      styles.uploadProgressTextWrapper
                                    }>
                                    <span className={styles.uploadInfoText}>
                                      Uploading file
                                    </span>
                                    <span className={styles.progressNumWrapper}>
                                      {uploadProgress}%
                                    </span>
                                  </div>
                                  <div className={styles.progressBarWrapper}>
                                    <div className={styles.progressBar}>
                                      <div
                                        className={styles.progress}
                                        style={{
                                          width: `${uploadProgress}%`,
                                        }}></div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {uploadProgress === 0 && (
                                <span className={styles.inputInfoText}>
                                  File added!
                                </span>
                              )}
                            </>
                          ) : (
                            <span className={styles.inputInfoText}>
                              File added!
                            </span>
                          )}

                          <div className={styles.inputUploadedWrapper}>
                            <input
                              className={styles.inputUploaded}
                              value={selectedFile?.csv?.file?.name}
                              readOnly={true}
                            />
                            <span
                              className={styles.changeUploaded}
                              onClick={handleClick}>
                              <img src={iconEdit} alt={'edit file'} />
                              change
                            </span>
                          </div>

                          <span className={styles.inputCaptionText}>
                            File size: 25 MB
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.iconWrapper}>
                        <img
                          className={styles.uploadIcon}
                          src={iconUploadBack}
                          alt={'upload'}
                        />
                        <div style={{ marginBottom: '2rem' }}>
                          <span className={styles.uploadText}>
                            {`Drag and drop file here or `}
                          </span>
                          <span
                            className={styles.uploadGreenText}
                            onClick={handleClick}>
                            Choose file
                          </span>
                        </div>
                        <div className={styles.descCaptionWrapper}>
                          <span className={styles.descCaptionText}>
                            Supported formats:{' '}
                            <span className={styles.descCaptionTextBold}>
                              CSV
                            </span>
                          </span>
                          <span className={styles.descCaptionDot}></span>
                          <span className={styles.descCaptionText}>
                            Max. size:{' '}
                            <span className={styles.descCaptionTextBold}>
                              25 MB
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.templateWrapper}>
                <div className={styles.templateDescWrapper}>
                  <span className={styles.templateHeader}>
                    Download{' '}
                    <span className={styles.templateCompanyText}>
                      {getValues('company')}
                    </span>{' '}
                    Template
                  </span>
                  <p className={styles.templateDesc}>
                    You can download the attached template and use it as a
                    starting point for your own file aa
                  </p>
                </div>
                <button
                  className={styles.downloadCta}
                  onClick={() =>
                    downloadFile(companyFiles[getValues('company')])
                  }>
                  <img src={iconDownload} alt={'download'} />
                  <span className={styles.buttonText}>Download</span>
                </button>
              </div>
              <div className={styles.formSubmitWrapper}>
                <button
                  className={styles.cancelCta}
                  onClick={() => {
                    setSelectedFile({
                      csv: {
                        file: null,
                        error: null,
                      },
                      zip: {
                        file: null,
                        error: null,
                      },
                    });
                    setCurrentScreen('screen1');
                    dispatch(closeModal());
                  }}>
                  cancel
                </button>
                {currentCompany === 'Spring Recruit' ? (
                  <button
                    className={`${styles.submitCta} ${!selectedFile?.csv?.file?.name
                      ? styles.submitCtaDisabled
                      : ''
                      }`}
                    onClick={handleFileSubmit}
                    disabled={!selectedFile?.csv?.file?.name}>
                    Submit
                  </button>
                ) : (
                  <button
                    className={`${styles.submitCta} ${!selectedFile?.csv?.file?.name
                      ? styles.submitCtaDisabled
                      : ''
                      }`}
                    onClick={() => setCurrentScreen('screen3')}
                    disabled={!selectedFile?.csv?.file?.name}>
                    Next
                  </button>
                )}
              </div>
            </>
          )}

          {currentScreen === 'screen3' &&
            currentCompany !== 'Spring Recruit' && (
              <>
                <div className={styles.captionRowWrapper}>
                  <div
                    className={styles.captionTextWrapper}
                    onClick={handleBack}>
                    <img src={iconArrowDark} alt={'go back'} />
                    <span className={styles.captionTextUpload}>
                      Upload file
                    </span>
                  </div>
                  <div className={styles.dropwdownWrapper}>
                    <span className={styles.dropwdownLabel}>template:</span>
                    <div style={{ position: 'relative', width: '21.2rem' }}>
                      <input
                        className={styles.inputFieldWrap}
                        id='priorityMinExp'
                        {...register('priorityMinExp')}
                        placeholder='Select'
                        readOnly
                        defaultValue={getValues('company')}
                        onClick={() => {
                          setSelectedIndices((prevState) => ({
                            ...prevState,
                            priorityMinExpIndex: 0,
                          }));
                          setCurrentDropdownStates((prevState) =>
                            prevState === 'priorityMinExp'
                              ? ''
                              : 'priorityMinExp'
                          );
                        }}
                        autoComplete='off'
                      />
                      <img
                        src={iconPointDown}
                        alt='pointDown'
                        style={{
                          position: 'absolute',
                          right: '1.6rem',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                      />
                      <DropdownComponent
                        currentDropdownState={currentDropdownStates}
                        dropdownRef={dropdownRefs['priorityMinExp']['ref']}
                        items={priorityObj}
                        selectedIndices={selectedIndices}
                        setValue={setValue}
                        setCurrentDropdownState={setCurrentDropdownStates}
                        dropdownStateName='priorityMinExp'
                        dropdownId='searchPriorityMinExp'
                        indexName='priorityMinExpIndex'
                        setSelectedIndices={setSelectedIndices}
                        getValues={getValues}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={
                      styles.uploadWrapper + ' ' + styles.marginBottom
                    }>
                    <div
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url('data:image/svg+xml,<svg viewBox="0 0 672 260" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="671" height="259" rx="7.5" fill="%23F5F9FE" stroke="${selectedFile?.zip?.error ? '%23ff5757' : '%23049F7A'
                          }" stroke-dasharray="7 7"/></svg>')`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        opacity: isDragging ? 0.5 : 1,
                        transition: 'opacity 0.3s ease',
                      }}
                      className={styles.marginBottom}>
                      <input
                        type='file'
                        id='fileInput'
                        style={{ display: 'none' }}
                        accept='application/zip'
                        onChange={handleFileChange}
                      />
                      {selectedFile?.zip?.file?.name ? (
                        <div className={styles.iconWrapper}>
                          <div className={styles.fileUploadedWrapper}>
                            {uploadProgress > 0 && (
                              <div>
                                <div
                                  className={styles.uploadProgressTextWrapper}>
                                  <span className={styles.uploadInfoText}>
                                    Uploading file
                                  </span>
                                  <span className={styles.progressNumWrapper}>
                                    {uploadProgress}%
                                  </span>
                                </div>
                                <div className={styles.progressBarWrapper}>
                                  <div className={styles.progressBar}>
                                    <div
                                      className={styles.progress}
                                      style={{
                                        width: `${uploadProgress}%`,
                                      }}></div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {uploadProgress === 0 && (
                              <span className={styles.inputInfoText}>
                                File added!
                              </span>
                            )}

                            <div className={styles.inputUploadedWrapper}>
                              <input
                                className={styles.inputUploaded}
                                value={selectedFile?.zip?.file?.name}
                                readOnly={true}
                              />
                              <span
                                className={styles.changeUploaded}
                                onClick={handleClick}>
                                <img src={iconEdit} alt={'edit file'} />
                                change
                              </span>
                            </div>

                            <span className={styles.inputCaptionText}>
                              File size: 25 MB
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.iconWrapper}>
                          <img
                            className={styles.uploadIcon}
                            src={iconUploadBack}
                            alt={'upload'}
                          />
                          <div style={{ marginBottom: '2rem' }}>
                            <span className={styles.uploadText}>
                              {`Drag and drop file here or `}
                            </span>
                            <span
                              className={styles.uploadGreenText}
                              onClick={handleClick}>
                              Choose file
                            </span>
                          </div>
                          <div className={styles.descCaptionWrapper}>
                            <span className={styles.descCaptionText}>
                              Supported formats:{' '}
                              <span className={styles.descCaptionTextBold}>
                                ZIP
                              </span>
                            </span>
                            <span className={styles.descCaptionDot}></span>
                            <span className={styles.descCaptionText}>
                              Max. size:{' '}
                              <span className={styles.descCaptionTextBold}>
                                25 MB
                              </span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.templateWrapper}>
                  <div className={styles.templateDescWrapper}>
                    <span className={styles.templateHeader}>
                      Download Zip file
                    </span>
                    <p className={styles.templateDesc}>
                      You can download the sample zip file and use it as a
                      reference.
                    </p>
                  </div>
                  <button
                    className={styles.downloadCta}
                    onClick={() => downloadFile(resumesZip)}>
                    <img src={iconDownload} alt={'download'} />
                    <span className={styles.buttonText}>Download</span>
                  </button>
                </div>
                <div className={styles.formSubmitWrapper}>
                  <button
                    className={styles.cancelCta}
                    onClick={() => {
                      setSelectedFile({
                        csv: {
                          file: null,
                          error: null,
                        },
                        zip: {
                          file: null,
                          error: null,
                        },
                      });
                      setCurrentScreen('screen1');
                      dispatch(closeModal());
                    }}>
                    cancel
                  </button>
                  <button
                    className={`${styles.submitCta} ${!selectedFile?.zip?.file?.name
                      ? styles.submitCtaDisabled
                      : ''
                      }`}
                    onClick={handleFileSubmit}
                    disabled={!selectedFile?.zip?.file?.name}>
                    Submit
                  </button>
                </div>
              </>
            )}

          {currentScreen === 'screen4' && (
            <div className={styles.successWrapper}>
              <img src={iconSuccess} alt={'success'} />
              <div className={styles.successText}>
                Applicants uploaded successfully!
              </div>
              <button className={styles.successCta} onClick={goToApplicants}>
                View Applicants
              </button>
            </div>
          )}

          {currentScreen === 'screen5' && (
            <div className={styles.failureWrapper}>
              <img src={iconIncorrect} alt={'failed'} />
              <div className={styles.failureText}>Incorrect file format</div>
              <span className={styles.failureCaption}>
                The table does not match the required {currentCompany} format.
                Please review the errors and upload again.
              </span>
            </div>
          )}
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default BulkUpload;
