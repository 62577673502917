import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { DASHBOARD_API } from '../apiEndpoints';

export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchData',
  async (queryParams) => {
    const response = await axios.get(DASHBOARD_API, {
      params: queryParams,
    });
    // const sampleResponse = {
    //   appliacants_stats: {
    //     applied: {
    //       avg_tat: '69 days',
    //       count: 39,
    //       drop_off_rate: 69,
    //     },
    //     hired: {
    //       avg_tat: '69 days',
    //       count: 19,
    //       drop_off_rate: 69,
    //     },
    //     interviewed: {
    //       avg_tat: '69 days',
    //       count: 39,
    //       drop_off_rate: 69,
    //     },
    //     recommended: {
    //       avg_tat: '69 days',
    //       count: 39,
    //       drop_off_rate: 69,
    //     },
    //     shortlisted: {
    //       avg_tat: '69 days',
    //       count: 29,
    //       drop_off_rate: 69,
    //     },
    //   },
    //   applicants: {
    //     42: {
    //       current_role: 'Regulatory affairs officer',
    //       name: 'Rebecca Wood',
    //       relevancy_score: null,
    //       resume_filename: '/resumes/heart.bmp',
    //     },
    //     62: {
    //       current_role: 'fsdsf',
    //       name: 'yoloooo',
    //       relevancy_score: 12.5,
    //       resume_filename:
    //         '/resumes/c63030a2-d2ac-4db8-a595-649709a3aefb_Nishchay_Audichya.pdf',
    //     },
    //     64: {
    //       current_role: 'test',
    //       name: 'noneofthe',
    //       relevancy_score: 12.5,
    //       resume_filename:
    //         '/resumes/43b228a0-f9d4-4b0e-9223-9cf163ec215a_Nishchay_Audichya.pdf',
    //     },
    //   },
    //   jobs_posted: {
    //     37: {
    //       applications_received: 0,
    //       date_posted: 1715311169,
    //       job_title: 'Ceramics designer',
    //     },
    //     38: {
    //       applications_received: 0,
    //       date_posted: 1715311169,
    //       job_title: 'Social researcher',
    //     },
    //     39: {
    //       applications_received: 1,
    //       date_posted: 1715311169,
    //       job_title: 'Recruitment consultant',
    //     },
    //   },
    //   teams: [
    //     'ooga booga boo',
    //     'backend',
    //     'black',
    //     'grow',
    //     'father',
    //     'public',
    //     'follow',
    //     'real',
    //     'play',
    //     'realize',
    //     'she',
    //     'doctor',
    //     'manager',
    //     'hope',
    //     'million',
    //     'seem',
    //     'scene',
    //     'form',
    //     'yard',
    //     'when',
    //     'spring',
    //     'goal',
    //     'bill',
    //     'Mr',
    //     'growth',
    //     'any',
    //     'show',
    //     'response',
    //     'low',
    //     'something',
    //     'southern',
    //     'store',
    //   ],
    //   total_applicants: 54,
    //   total_jobs_posted: 39,
    //   your_stats: {
    //     average_relevancy_score: {
    //       delta: -69,
    //       industry_average: 80,
    //       value: 25.892857142857142,
    //     },
    //     average_time_to_hire_days: {
    //       delta: 69,
    //       value: '-778.3718',
    //     },
    //     conversion_rate: {
    //       delta: 69,
    //       value: 35.18518518518518,
    //     },
    //   },
    // };
    // return sampleResponse;
    return response.data;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: { data: {}, status: 'idle', error: null },
  reducers: {
    saveDataDashboard: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const { saveDataDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
