import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import fullUrl from '../../utils/fullUrl';
import styles from './SubmitModal.module.css';

import { closeModal } from '../../redux/submitModalSlice'; // import your closeModal and saveData actions

Modal.setAppElement('#root');

function SubmitModal() {
  const dispatch = useDispatch();
  const { isOpen, data } = useSelector((state) => state.submitModal);

  return (
    <Modal
      isOpen={isOpen}
      // onRequestClose={() => {
      //   dispatch(closeModal());
      // }}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          height: '50%',
        },
      }}>
      <div className={styles.wrapper}>
        <h3 className={styles.successFont}>{data?.message}</h3>
        {data?.job_url && (
          <div className={styles.urlWrapper}>
            <span className={styles.urlText}>Job Url:</span>
            <a
              className={styles.urlLink}
              href={fullUrl(data?.job_url)}
              rel='noopener noreferrer'>
              {fullUrl(data?.job_url)}
            </a>
          </div>
        )}
        <button
          className={styles.closeBtn}
          type='text'
          onClick={() => dispatch(closeModal())}>
          close
        </button>
      </div>
    </Modal>
  );
}

export default SubmitModal;
