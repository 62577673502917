import React, { useState } from 'react';
import styles from './ModalSkeleton.module.css';

const ModalSkeleton = ({ children = null }) => {
    if (!children) return null;
    return <div className={styles.modalSkeleton}>
        {children}
    </div>
};

export default ModalSkeleton;
