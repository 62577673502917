import { useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';

const usePdfThumbnail = (file, canvasRef, maxWidthRem, maxHeightRem) => {
  useEffect(() => {
    if (file && canvasRef.current) {
      const rootFontSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      );
      const maxWidthPx = maxWidthRem ? maxWidthRem * rootFontSize : null;
      const maxHeightPx = maxHeightRem ? maxHeightRem * rootFontSize : null;

      const fileReader = new FileReader();

      fileReader.onload = async (event) => {
        try {
          const typedArray = new Uint8Array(event.target.result);
          const pdfDoc = await pdfjsLib.getDocument({ data: typedArray })
            .promise;
          const page = await pdfDoc.getPage(1);
          const originalViewport = page.getViewport({ scale: 1 });

          let scale;
          if (maxWidthPx && maxHeightPx) {
            const scaleWidth = maxWidthPx / originalViewport.width;
            const scaleHeight = maxHeightPx / originalViewport.height;
            scale = Math.min(scaleWidth, scaleHeight);
          } else if (maxWidthPx) {
            scale = maxWidthPx / originalViewport.width;
          } else if (maxHeightPx) {
            scale = maxHeightPx / originalViewport.height;
          } else {
            scale = 1;
          }

          const viewport = page.getViewport({ scale });
          const context = canvasRef.current.getContext('2d');
          canvasRef.current.height = viewport.height;
          canvasRef.current.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
        } catch (error) {
          console.error('Error rendering PDF thumbnail:', error);
        }
      };

      fileReader.readAsArrayBuffer(file);
    }
  }, [file, canvasRef, maxWidthRem, maxHeightRem]);
};

export default usePdfThumbnail;
