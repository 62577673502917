import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { iconCross } from '../../assets';
import styles from './CustomCriteriaApply.module.css';
import CriteriaApply from '../CriteriaApply/CriteriaApply';

import {
  keyMapping,
  serverToKeyMap,
  clientServerKeyApplyMap,
} from '../../constants/customCriteriaMap';

const priorityObj = [
  {
    value: '3',
    name: 'High',
  },
  {
    value: '2',
    name: 'Mid',
  },
  {
    value: '1',
    name: 'Low',
  },
  {
    value: '0',
    name: 'No preference',
  },
];

function filterPreferences(data) {
  const result = {};
  for (const key in data) {
    if (
      key.endsWith('_preference') &&
      data[key] !== '' &&
      (!Array.isArray(data[key]) || data[key].length > 0)
    ) {
      const label = clientServerKeyApplyMap[key];
      result[label] = data[key];
    }
  }

  return result;
}

// function filterPreferences(data) {
//   console.log('data: ', data);
// }

const CustomCriteriaApply = ({
  setValueParentForm,
  defaultValues,
  filteredContent,
}) => {
  const [currentDropdownStates, setCurrentDropdownStates] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      educationalQualification_preference: [],
      languagesKnown_preference: [],
      tierOfInstitute_preference: [],
      fieldOfStudy_preference: [],
    },
  });

  useEffect(() => {
    const subscription = watch((value) => {
      const values = filterPreferences(value);
      setValueParentForm('criteria', values);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const commonProps = {
    register,
    setCurrentDropdownStates,
    currentDropdownStates,
    priorityObj,
    setValue,
    getValues,
    watch,
    defaultValues,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalContent}>
        <div className={styles.contentWrapper}>
          {Array.from(
            new Set(
              Object.keys(filteredContent).map((item) => {
                return serverToKeyMap[item];
              })
            )
          ).map((key) => {
            const value = keyMapping[key];

            return (
              <CriteriaApply
                key={key}
                forLabel={key}
                nameToShow={value['fieldName']}
                preferences={value['options']}
                type={value['applyType']}
                {...commonProps}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomCriteriaApply;
