import { useState, useEffect } from 'react';
import styles from './DropdownMultiple.module.css';

import { get } from 'lodash';

const {
  teamDropdown,
  teamDropdownItem,
  highlightedDropdownItem,
  selectedBackground,
} = styles;

const DropdownComponentMultiple = ({
  currentDropdownState,
  dropdownRef,
  items,
  selectedIndices,
  dropdownStateName,
  dropdownId,
  indexName,
  setValue,
  values,
}) => {
  const toggleValue = (value) => {
    if (values.includes(value)) {
      setValue(
        dropdownStateName,
        values.filter((v) => v !== value)
      );
    } else {
      setValue(dropdownStateName, [value, ...values]);
    }
  };

  return (
    currentDropdownState === dropdownStateName && (
      <div className={teamDropdown} ref={dropdownRef}>
        <div tabIndex={0} id={dropdownId} className={styles.dropdownScroll}>
          {items?.map((item, index) => {
            const isSelected = values.includes(item.name);

            return (
              <span
                key={item.name}
                className={`${teamDropdownItem} ${
                  selectedIndices[indexName] === index
                    ? highlightedDropdownItem
                    : ''
                } ${isSelected ? selectedBackground : ''}`}
                onClick={() => toggleValue(item.name)}>
                {item.name}
              </span>
            );
          })}
        </div>
      </div>
    )
  );
};

export default DropdownComponentMultiple;
