import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: { isOpen: false },
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    // saveData: (state, action) => {
    //   Object.keys(state).forEach((key) => {
    //     delete state[key];
    //   });
    //   Object.assign(state, action.payload);
    // },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
