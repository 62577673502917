import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { openModal, closeModal } from '../redux/submitModalSlice';
import { CREATE_JOB_API } from '../apiEndpoints';

export const fetchCreateJobData = createAsyncThunk(
  'createJob/fetchData',
  async (queryParams) => {
    const response = await axios.get(CREATE_JOB_API, {
      params: queryParams,
    });
    return response.data;
  }
);
export const postCreateJobData = createAsyncThunk(
  'createJob/postData',
  async (jobData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: 'post',
        url: CREATE_JOB_API,
        data: jobData,
        headers: { 'Content-Type': 'application/json' },
      });
      dispatch(
        openModal({ ...response.data, message: 'Job posted successfully!' })
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createJobSlice = createSlice({
  name: 'createJob',
  initialState: { data: {}, status: 'idle', error: null },
  reducers: {
    saveDataCreateJob: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateJobData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCreateJobData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchCreateJobData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(postCreateJobData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postCreateJobData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(postCreateJobData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const { saveDataCreateJob } = createJobSlice.actions;
export default createJobSlice.reducer;
