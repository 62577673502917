// components/ToastContainer.jsx
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideToast } from '../../redux/toastSlice';
import Toast from './Toast'; // Import your Toast component

const ToastContainer = () => {
  const dispatch = useDispatch();
  const { isVisible, message, type } = useSelector((state) => state.toast);

  const handleClose = () => {
    dispatch(hideToast());
  };

  return (
    <>
      {isVisible && (
        <Toast message={message} type={type} onClose={handleClose} />
      )}
    </>
  );
};

export default ToastContainer;
