import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchDashboardData } from '../../redux/dashboardSlice';
import styles from './Dashboard.module.css';
import { get } from 'lodash';
import {
  iconSearch,
  iconPositive,
  iconClockRed,
  iconDown,
  iconUp,
  iconDownCaret,
  iconFilter,
  iconRight,
  iconClockGreen,
  iconClockOrange,
} from '../../assets';
function convertUnixTimestampToDaysAgo(unixTimestamp) {
  const datePosted = new Date(unixTimestamp * 1000);
  const currentDate = new Date();
  const differenceInTime = currentDate.getTime() - datePosted.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  return `Posted ${differenceInDays} days ago`;
}

const relevencyMap = {
  high: {
    src: iconClockGreen,
    backgroundColor: '#18ae1e1a',
    color: '#18AE1E',
  },
  mid: {
    src: iconClockOrange,
    color: '#D86D3A',
    backgroundColor: '#D86D3A1A',
  },
  low: {
    src: iconClockRed,
    backgroundColor: '#FF57571A',
    color: '#FF5757',
  },
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.dashboard);
  const [selectedOptions, setSelectedOptions] = useState({
    team: '',
    postedOn: '6Months',
  });

  const handleSelectChange = (e) => {
    setSelectedOptions({
      ...selectedOptions,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilter = () => {
    const filteredOptions = Object.entries(selectedOptions).reduce(
      (acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    dispatch(fetchDashboardData(filteredOptions));
  };

  useEffect(() => {
    dispatch(fetchDashboardData({ postedOn: '6Months' }));
  }, [dispatch]);

  const {
    applied,
    hired,
    interviewed,
    recommended,
    shortlisted,
    role_offered,
  } = get(data, 'appliacants_stats', {
    applied: {},
    hired: {},
    interviewed: {},
    recommended: {},
    shortlisted: {},
    role_offered: {},
  });

  const applicationsStatsCounts = [
    applied.count,
    recommended.count,
    shortlisted.count,
    interviewed.count,
    role_offered.count,
    hired.count,
  ];

  const getColor = (score) =>
    score > 70 ? 'high' : score > 30 ? 'mid' : 'low';

  return (
    <section className={styles.dashboardMainContent}>
      <header className={styles.dashboardHeader}>
        <div className={styles.dashboardTitleWrap}>
          <h1 className={styles.dashboardTitle}>home</h1>
          <span className={styles.dashboardTrialWrap}>
            <span className={styles.dashboardTrialDays}>
              {get(data, 'trial_left', 0)} days
            </span>{' '}
            of trial left
          </span>
        </div>
        <div className={styles.dashboardHeaderBox}>
          <div style={{ position: 'relative', marginRight: '1.6rem' }}>
            <img
              src={iconSearch}
              alt='search'
              style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
              }}
            />
            {/* <img src={iconSearch} alt='search' /> */}
            <input
              className={styles.dashboardSearchWrap}
              placeholder='Search for applicants and jobs'
              style={{ paddingLeft: '40px', textAlign: 'left' }}
            />
          </div>
          <button
            className={styles.dashboardJobCta}
            onClick={() => {
              navigate('/client/create-job');
            }}>
            <img src={iconPositive} alt='positive' />
            <span className={styles.dashboardJobText}>Create a job</span>
          </button>
        </div>
      </header>
      <div className={styles.dashboardFiltersBox}>
        <div className={styles.dashboardFilter}>
          <span className={styles.dashboardFilterTitle}>teams</span>
          <div className={styles.selectContainer}>
            <select
              className={styles.styledSelect}
              name='team'
              value={selectedOptions.team}
              onChange={handleSelectChange}>
              <option defaultValue='' value={''}>
                All teams
              </option>
              {data &&
                data.teams &&
                data.teams.length > 0 &&
                data.teams.map((team) => (
                  <option key={team} value={team}>
                    {team}
                  </option>
                ))}
            </select>
            <img
              className={styles.selectIcon}
              src={iconDownCaret}
              alt='icondowncaret'
            />
          </div>
        </div>

        <div className={styles.dashboardFilter}>
          <span className={styles.dashboardFilterTitle}>Posted on</span>
          <div className={styles.selectContainer}>
            <select
              className={styles.styledSelect}
              name='postedOn'
              value={selectedOptions.postedOn}
              onChange={handleSelectChange}>
              <option value='1Day'>Last 1 day</option>
              <option value='1Week'>Last 1 week</option>
              <option value='1Month'>Last 1 month</option>
              <option value='3Months'>Last 3 months</option>
              <option value='6Months'>Last 6 months</option>
              <option value='1Year'>Last 1 Year</option>
              <option value='allTime'>All time</option>
            </select>
            <img
              className={styles.selectIcon}
              src={iconDownCaret}
              alt='icondowncaret'
            />
          </div>
        </div>
        <button className={styles.dashboardFilterCTA} onClick={handleFilter}>
          <img src={iconFilter} alt='filter' />
          <span className={styles.dashboardFilterText}>Add filters</span>
        </button>
      </div>
      <div className={styles.dashboardBox}>
        <h3 className={styles.dashboardSecondaryTitle + ' ' + styles.pdBt}>
          Your statistics
        </h3>
        <p className={styles.dashboardSecondarySubTitle}>
          Monthly performance metrics for your job postings and their
          effectiveness
        </p>

        <div className={styles.dashboardCardBox}>
          <div className={styles.dashboardCard}>
            <span className={styles.dashboardScoreTitle}>
              Average Relevancy Score
            </span>
            <span className={styles.dashboardNumberBox}>
              <span
                className={styles.dashboardCardNumber}
                style={
                  get(data, 'your_stats.average_relevancy_score.value', 0) >=
                  get(
                    data,
                    'your_stats.average_relevancy_score.industry_average',
                    0
                  )
                    ? { color: '#18AE1E' }
                    : { color: '#ff5757' }
                }>
                {Number(
                  get(data, 'your_stats.average_relevancy_score.value', 0)
                ).toFixed(2)}
                %
              </span>
              <img
                className={styles.dashboardCardNumberIcon}
                src={
                  get(data, 'your_stats.average_relevancy_score.value', 0) >=
                  get(
                    data,
                    'your_stats.average_relevancy_score.industry_average',
                    0
                  )
                    ? iconClockGreen
                    : iconClockRed
                }
                alt='clock'
              />
            </span>
            <span className={styles.dashboardCaptionBox}>
              {get(data, 'your_stats.average_relevancy_score.value', 0) ===
              0 ? (
                <span className={styles.dashboardCaption}>
                  {`${get(
                    data,
                    'your_stats.average_relevancy_score.industry_average',
                    0
                  )}% is current industry average`}
                </span>
              ) : (
                <>
                  <img
                    src={
                      get(data, 'your_stats.average_relevancy_score.delta', 0) >
                      0
                        ? iconUp
                        : get(
                            data,
                            'your_stats.average_relevancy_score.delta',
                            0
                          ) < 0
                        ? iconDown
                        : iconUp
                    }
                    alt='status-icon'
                  />
                  <span
                    className={styles.dashboardCaption}
                    style={{
                      color:
                        get(
                          data,
                          'your_stats.average_relevancy_score.delta',
                          0
                        ) > 0
                          ? '#18AE1E'
                          : get(
                              data,
                              'your_stats.average_relevancy_score.delta',
                              0
                            ) < 0
                          ? '#FF5757'
                          : '#000000',
                      marginRight: '4px',
                    }}>
                    {Math.abs(
                      get(data, 'your_stats.average_relevancy_score.delta', 0)
                    ) !== 0
                      ? `${Math.abs(
                          get(data, 'your_stats.average_relevancy_score.delta')
                        )}`
                      : null}
                  </span>
                  <span className={styles.dashboardCaption}>
                    {get(data, 'your_stats.average_relevancy_score.delta', 0) >
                    0
                      ? 'higher than'
                      : get(
                          data,
                          'your_stats.average_relevancy_score.delta',
                          0
                        ) < 0
                      ? 'lower than'
                      : 'same as'}{' '}
                    industry average
                  </span>
                </>
              )}
            </span>
          </div>
          <div className={styles.dashboardCard}>
            <span className={styles.dashboardScoreTitle}>Shortlist rate</span>
            <span className={styles.dashboardNumberBox}>
              <span
                className={styles.dashboardCardNumber + ' ' + styles.fontBlack}>
                {Number(
                  get(data, 'your_stats.conversion_rate.value', 0)
                ).toFixed(2)}
                %
              </span>
            </span>
            <span className={styles.dashboardCaptionBox}>
              {get(data, 'your_stats.conversion_rate.value', 0) === 0 ? (
                <span className={styles.dashboardCaption}>
                  {`${get(
                    data,
                    'your_stats.conversion_rate.industry_average',
                    0
                  )}% is current industry average`}
                </span>
              ) : (
                <>
                  <img
                    src={
                      get(data, 'your_stats.conversion_rate.delta', 0) > 0
                        ? iconUp
                        : get(data, 'your_stats.conversion_rate.delta', 0) < 0
                        ? iconDown
                        : iconUp
                    }
                    alt='status-icon'
                  />
                  <span
                    className={styles.dashboardCaption}
                    style={{
                      color:
                        get(data, 'your_stats.conversion_rate.delta', 0) > 0
                          ? '#18AE1E'
                          : get(data, 'your_stats.conversion_rate.delta', 0) < 0
                          ? '#FF5757'
                          : '#000000',
                      marginRight: '4px',
                    }}>
                    {Math.abs(
                      get(data, 'your_stats.conversion_rate.delta', 0)
                    ) !== 0
                      ? `${Math.abs(
                          get(data, 'your_stats.conversion_rate.delta')
                        )}`
                      : null}
                  </span>
                  <span className={styles.dashboardCaption}>
                    {get(data, 'your_stats.conversion_rate.delta', 0) > 0
                      ? 'higher than'
                      : get(data, 'your_stats.conversion_rate.delta', 0) < 0
                      ? 'lower than'
                      : 'same as'}{' '}
                    industry average
                  </span>
                </>
              )}
            </span>
          </div>
          <div className={styles.dashboardCard}>
            <span className={styles.dashboardScoreTitle}>
              Avg. time to hire
            </span>
            <span className={styles.dashboardNumberBox}>
              <span
                className={styles.dashboardCardNumber + ' ' + styles.fontBlack}>
                {Number(
                  get(data, 'your_stats.average_time_to_hire_days.value', 0)
                ).toFixed(1)}{' '}
                days
              </span>
            </span>
            <span className={styles.dashboardCaptionBox}>
              {get(data, 'your_stats.average_time_to_hire_days.value', 0) ===
              0 ? (
                <span className={styles.dashboardCaption}>
                  {`${get(
                    data,
                    'your_stats.average_time_to_hire_days.industry_average',
                    0
                  )}% is current industry average`}
                </span>
              ) : (
                <>
                  <img
                    src={
                      get(
                        data,
                        'your_stats.average_time_to_hire_days.delta',
                        0
                      ) > 0
                        ? iconUp
                        : get(
                            data,
                            'your_stats.average_time_to_hire_days.delta',
                            0
                          ) < 0
                        ? iconDown
                        : iconUp
                    }
                    alt='status-icon'
                  />
                  <span
                    className={styles.dashboardCaption}
                    style={{
                      color:
                        get(
                          data,
                          'your_stats.average_time_to_hire_days.delta',
                          0
                        ) > 0
                          ? '#18AE1E'
                          : get(
                              data,
                              'your_stats.average_time_to_hire_days.delta',
                              0
                            ) < 0
                          ? '#FF5757'
                          : '#000000',
                      marginRight: '4px',
                    }}>
                    {Math.abs(
                      get(data, 'your_stats.average_time_to_hire_days.delta', 0)
                    ) !== 0
                      ? `${Math.abs(
                          get(
                            data,
                            'your_stats.average_time_to_hire_days.delta'
                          )
                        )}`
                      : null}
                  </span>
                  <span className={styles.dashboardCaption}>
                    {get(
                      data,
                      'your_stats.average_time_to_hire_days.delta',
                      0
                    ) > 0
                      ? 'higher than'
                      : get(
                          data,
                          'your_stats.average_time_to_hire_days.delta',
                          0
                        ) < 0
                      ? 'lower than'
                      : 'same as'}{' '}
                    industry average
                  </span>
                </>
              )}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.dashboardApplicationBox}>
        <h3 className={styles.dashboardSecondaryTitle + ' ' + styles.pdBt}>
          Applications
        </h3>
        <div className={styles.dashboardPolygonBox}>
          <div className={styles.fullDimensions}>
            <div
              style={{
                clipPath: `polygon(0% 0%, 100% ${
                  (100 -
                    (applicationsStatsCounts[1] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[1] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%, 0% 100%)`,
                backgroundColor: '#EAF9FF',
              }}
              className={styles.dashboardPolygon}>
              {' '}
            </div>
          </div>
          <div className={styles.fullDimensions}>
            <div
              style={{
                clipPath: `polygon(0% ${
                  (100 -
                    (applicationsStatsCounts[1] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  (100 -
                    (applicationsStatsCounts[2] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[2] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%, 0% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[1] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%)`,
                backgroundColor: '#E0F2FF',
              }}
              className={styles.dashboardPolygon}>
              {' '}
            </div>
          </div>
          <div className={styles.fullDimensions}>
            <div
              style={{
                clipPath: `polygon(0% ${
                  (100 -
                    (applicationsStatsCounts[2] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  (100 -
                    (applicationsStatsCounts[3] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[3] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%, 0% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[2] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%)`,
                backgroundColor: '#F3E7FF',
              }}
              className={styles.dashboardPolygon}>
              {' '}
            </div>
          </div>
          <div className={styles.fullDimensions}>
            <div
              style={{
                clipPath: `polygon(0% ${
                  (100 -
                    (applicationsStatsCounts[3] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  (100 -
                    (applicationsStatsCounts[4] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[4] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%, 0% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[3] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%)`,
                backgroundColor: '#FBF5DC',
              }}
              className={styles.dashboardPolygon}>
              {' '}
            </div>
          </div>
          <div className={styles.fullDimensions}>
            <div
              style={{
                clipPath: `polygon(0% ${
                  (100 -
                    (applicationsStatsCounts[4] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  (100 -
                    (applicationsStatsCounts[5] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[5] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%, 0% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[4] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%)`,
                backgroundColor: '#EDFFEA',
              }}
              className={styles.dashboardPolygon}>
              {' '}
            </div>
          </div>
          <div
            style={{
              borderRight: 'none',
            }}
            className={styles.fullDimensions}>
            <div
              style={{
                clipPath: `polygon(0% ${
                  (100 -
                    (applicationsStatsCounts[5] / applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  (100 -
                    (applicationsStatsCounts[5] /
                      2 /
                      applicationsStatsCounts[0]) *
                      100) /
                  2
                }%, 100% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[5] /
                      2 /
                      applicationsStatsCounts[0]) *
                      100) /
                    2
                }%, 0% ${
                  100 -
                  (100 -
                    (applicationsStatsCounts[5] / applicationsStatsCounts[0]) *
                      100) /
                    2
                }%)`,
                backgroundColor: '#FFEEF3',
              }}
              className={styles.dashboardPolygon}>
              {' '}
            </div>
          </div>
        </div>
        <div className={styles.dashboardApplicationColumnBox}>
          <div className={styles.dashboardApplicationColumn}>
            <div className={styles.dashboardApplicationStatsBox}>
              <div className={styles.dashboardApplicationStats}>
                <div className={styles.dashboardApplicationNum}>
                  <span className={styles.appliedNum}>{applied.count}</span>
                  <span className={styles.appliedText}>applied</span>
                </div>
                <div className={styles.dashboardApplicationDrop}>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>conversion:</span>
                    <span className={styles.dropNum}>N/A</span>
                  </span>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>Avg. TAT:</span>
                    <span className={styles.dropNum}>{applied.avg_tat}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dashboardApplicationColumn}>
            <div className={styles.dashboardApplicationStatsBox}>
              <div className={styles.dashboardApplicationStats}>
                <div className={styles.dashboardApplicationNum}>
                  <span className={styles.appliedNum}>{recommended.count}</span>
                  <span className={styles.appliedText}>Recommended</span>
                </div>
                <div className={styles.dashboardApplicationDrop}>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>conversion:</span>
                    <span className={styles.dropNum}>
                      {recommended.drop_off_rate}%
                    </span>
                  </span>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>Avg. TAT:</span>
                    <span className={styles.dropNum}>N/A</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dashboardApplicationColumn}>
            <div className={styles.dashboardApplicationStatsBox}>
              <div className={styles.dashboardApplicationStats}>
                <div className={styles.dashboardApplicationNum}>
                  <span className={styles.appliedNum}>{shortlisted.count}</span>
                  <span className={styles.appliedText}>Shortlisted</span>
                </div>
                <div className={styles.dashboardApplicationDrop}>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>conversion:</span>
                    <span className={styles.dropNum}>
                      {shortlisted.drop_off_rate}%
                    </span>
                  </span>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>Avg. TAT:</span>
                    <span className={styles.dropNum}>
                      {shortlisted.avg_tat}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dashboardApplicationColumn}>
            <div className={styles.dashboardApplicationStatsBox}>
              <div className={styles.dashboardApplicationStats}>
                <div className={styles.dashboardApplicationNum}>
                  <span className={styles.appliedNum}>{interviewed.count}</span>
                  <span className={styles.appliedText}>Interviewed</span>
                </div>
                <div className={styles.dashboardApplicationDrop}>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>conversion:</span>
                    <span className={styles.dropNum}>
                      {interviewed.drop_off_rate}%
                    </span>
                  </span>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>Avg. TAT:</span>
                    <span className={styles.dropNum}>
                      {interviewed.avg_tat}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dashboardApplicationColumn}>
            <div className={styles.dashboardApplicationStatsBox}>
              <div className={styles.dashboardApplicationStats}>
                <div className={styles.dashboardApplicationNum}>
                  <span className={styles.appliedNum}>
                    {role_offered.count}
                  </span>
                  <span className={styles.appliedText}>Role offered</span>
                </div>
                <div className={styles.dashboardApplicationDrop}>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>conversion:</span>
                    <span className={styles.dropNum}>
                      {role_offered.drop_off_rate}%
                    </span>
                  </span>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>Avg. TAT:</span>
                    <span className={styles.dropNum}>
                      {role_offered.avg_tat}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dashboardApplicationColumn}>
            <div className={styles.dashboardApplicationStatsBox}>
              <div className={styles.dashboardApplicationStats}>
                <div className={styles.dashboardApplicationNum}>
                  <span className={styles.appliedNum}>{hired.count}</span>
                  <span className={styles.appliedText}>Joined</span>
                </div>
                <div className={styles.dashboardApplicationDrop}>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>conversion:</span>
                    <span className={styles.dropNum}>
                      {hired.drop_off_rate}%
                    </span>
                  </span>
                  <span className={styles.dropBox}>
                    <span className={styles.dropText}>Avg. TAT:</span>
                    <span className={styles.dropNum}>{hired.avg_tat}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className={styles.dashboardListBox}>
        <div className={styles.dashboardList}>
          <div className={styles.listHeaderBox + ' ' + styles.pdBt}>
            <h3 className={styles.dashboardSecondaryTitle}>Jobs Posted</h3>
            <span className={styles.listHeaderNumBox}>
              <span className={styles.listHeaderNum}>
                View all ({data && data.total_jobs_posted})
              </span>
              <img src={iconRight} alt='right' />
            </span>
          </div>

          {data &&
            data.jobs_posted &&
            Object.keys(data.jobs_posted).map((jobId) => {
              const job = data.jobs_posted[jobId];
              if (!job || Object.keys(job).length === 0) return null;
              return (
                <div className={styles.listJobBox} key={jobId}>
                  <div className={styles.listJobDescBox}>
                    <span className={styles.listJobDescText}>
                      {job.job_title}
                    </span>
                    <span className={styles.listJobDescNum}>
                      {job.applications_received} Applicants -{' '}
                      {convertUnixTimestampToDaysAgo(job.date_posted)}
                    </span>
                  </div>
                  <button
                    onClick={() => {
                      window.location = `/view_applicants/${jobId}`;
                    }}
                    className={styles.listJobDescCTA}>
                    view
                  </button>
                </div>
              );
            })}
        </div>

        <div className={styles.dashboardList}>
          <div className={styles.listHeaderBox + ' ' + styles.pdBt}>
            <h3 className={styles.dashboardSecondaryTitle}>Applicants</h3>
            <span className={styles.listHeaderNumBox}>
              <span className={styles.listHeaderNum}>
                View all ({data && data.total_applicants})
              </span>
              <img src={iconRight} alt='right' />
            </span>
          </div>

          {data &&
            data.applicants &&
            Object.keys(data.applicants).map((jobId) => {
              const job = data.applicants[jobId];
              if (!job || Object.keys(job).length === 0) return null;
              return (
                <div className={styles.listJobBox} key={jobId}>
                  <div className={styles.listJobBoxFirstCol}>
                    <div
                      className={styles.relevencyBox}
                      style={{
                        backgroundColor:
                          relevencyMap[getColor(job.relevancy_score)]
                            .backgroundColor,
                      }}>
                      <div className={styles.relevency}>
                        <span
                          className={styles.relevencyBoxScore}
                          style={{
                            color:
                              relevencyMap[getColor(job.relevancy_score)].color,
                          }}>
                          {Number(get(job, 'relevancy_score', 0)).toFixed(2)}%
                        </span>
                        <img
                          className={styles.relevencyBoxImg}
                          src={relevencyMap[getColor(job.relevancy_score)].src}
                          alt='clock'
                        />
                      </div>
                      <span className={styles.relevencyBoxText}>Relevancy</span>
                    </div>
                    <div className={styles.listJobDescBox}>
                      <span className={styles.listJobDescText}>{job.name}</span>
                      <span className={styles.listJobDescNum}>
                        {job.current_role}
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      window.location = job.resume_filename;
                    }}
                    className={styles.listJobDescCTA}>
                    view
                  </button>
                </div>
              );
            })}
        </div>
      </section>
    </section>
  );
};

export default Dashboard;
