import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { openModal, closeModal } from './submitModalSlice';
import { SIGNUP_API, VERIFY_OTP_API } from '../apiEndpoints';

export const postSignup = createAsyncThunk(
  'signUp/postData',
  async (signUpData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: 'post',
        url: SIGNUP_API,
        data: signUpData,
        headers: { 'Content-Type': 'application/json' },
      });
      //   dispatch(openModal(response.data));
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log('err.response: ', err.response);
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  'signUp/verifyOtp',
  async (verifyOtp, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: 'post',
        url: VERIFY_OTP_API,
        data: verifyOtp,
        headers: { 'Content-Type': 'application/json' },
      });
      //   dispatch(openModal(response.data));
      console.log('response.data: ', response.data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log('err.response: ', err.response);
      return rejectWithValue(err.response.data);
    }
  }
);
export const signUpSlice = createSlice({
  name: 'signUp',
  initialState: { data: {}, status: 'idle', error: null },
  reducers: {
    saveDataCreateJob: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSignup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postSignup.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(postSignup.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const { saveDataCreateJob } = signUpSlice.actions;
export default signUpSlice.reducer;
